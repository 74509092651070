import ExamResultStatisRank from '@Core/Components/Common/ExamResult/Table/ExamResultStatisRank'
import CoreTable, { columnHelper } from '@Core/Components/Table/CoreTable'
import { CoreActionIcon } from '@Core/Components/Table/components/CoreTableAction'
import { renderTime } from '@Core/Helpers/Date'
import { openExamTestUrl } from '@Core/Helpers/Exam'
import { useCorePageContext } from '@Core/Provider/CorePageProvider'
import { Analytics, FactCheck, OpenInNew } from '@mui/icons-material'
import { Gauge, gaugeClasses } from '@mui/x-charts'
import React, { useMemo } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import urlcat from 'urlcat'
import { ROUTER_WORKSPACE_CLASS_ROOM } from '../../../configs/const'
import AvgTotalScore from '../../../components/Table/AvgTotalScore'
import ExamAction from '../../../components/Table/ExamAction'
// import PropTypes from 'prop-types'

const StudentExamTable = props => {
	const { tableHandler, t, classRoomDetail, student, isFeatureStudent } = useCorePageContext()
	const [search, setSearch] = useSearchParams()

	const navigate = useNavigate()
	const columns = useMemo(
		() =>
			[
				columnHelper.accessor('name', {
					header: t('Đề thi')
				}),
				columnHelper.accessor('total_question', {
					header: t('Tổng câu hỏi')
				}),
				columnHelper.accessor('total_result_study_test', {
					header: t('Lượt ôn tập')
				}),
				columnHelper.accessor('total_time_used.study_test', {
					header: t('Tổng thời gian ôn tập'),
					cell: ({ getValue }) => (getValue() > 0 ? renderTime(getValue()) : null)
				}),
				columnHelper.accessor('total_result_test', {
					header: t('Lượt thi thử')
				}),

				columnHelper.accessor('total_time_used.test', {
					header: t('Tổng thời gian thi thử'),
					cell: ({ getValue }) => (getValue() > 0 ? renderTime(getValue()) : null)
				}),

				columnHelper.accessor('avg_total_score_test', {
					header: t('Điểm trung bình'),
					cell: ({ getValue }) => <AvgTotalScore total_score={getValue()} />
				}),
				columnHelper.accessor('statis_rank', {
					header: t('Thống kê xếp loại kết quả'),
					cell: ({ row, getValue }) => {
						const rank_data = getValue()
						return <ExamResultStatisRank rank_data={rank_data} />
					}
				}),
				columnHelper.accessor('id', {
					header: t('Hành động'),
					cell: ({ getValue, row }) => (
						<ExamAction classRoomDetail={classRoomDetail} exam={row.original} isFeatureStudent />
					)
				})
			].filter(Boolean),
		[t, student]
	)

	return <CoreTable isShowPagination columns={columns} {...tableHandler} />
}

// StudentExamTable.defaultProps = {}

// StudentExamTable.propTypes = {}

export default React.memo(StudentExamTable)
