import { useUpdateEffect } from 'ahooks'
import React, { useEffect, useState } from 'react'
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom'
import TabHeader from './TabHeader'
import { getQueryUrlObject } from '@Core/Helpers/Url'
// import PropTypes from 'prop-types'

const CorePageTabContext = React.createContext({
	tabs: [],
	tabActive: '',
	setTabActive: tab => {}
})

export const useCorePageTabContext = () => React.useContext(CorePageTabContext)

const CorePageTabLayout = ({ children, detault, tabs = [], tabAction, ...resProps }) => {
	const [tabActive, setTabActive] = useState(detault)
	const navigate = useNavigate()
	const [searchParams, setSearchParams] = useSearchParams()
	const tab = searchParams.get('tab')
	// useUpdateEffect(() => {
	// navigate(
	// 	{
	// 		pathname: window.location.pathname,
	// 		search: createSearchParams({
	// 			tab: tabActive
	// 		}).toString()
	// 	},
	// 	{ replace: true }
	// )
	// console.log('============= searchParams.entries',searchParams.entries)
	// setSearchParams({ tab: tabActive })
	// }, [tabActive])

	useEffect(() => {
		if (tab) {
			setTabActive(tab)
		}
	}, [tab])

	const handleChangeTab = tab => {
		setSearchParams({ tab })
		setTabActive(tab)
	}

	const renderContent = () => {
		const tabObject = tabs.find(i => i?.key === tabActive)
		return tabObject?.content
	}

	// useUpdateEffect(() => {
	// 	if (create_type) {
	// 		setActiveStep('basic_info')
	// 	}
	// }, [create_type])

	const context = {
		tabs,
		tabActive,
		setTabActive,
		handleChangeTab,
		tabAction,
		...resProps
	}

	return (
		<CorePageTabContext.Provider value={context}>
			<TabHeader />
			{renderContent()}
		</CorePageTabContext.Provider>
	)
}

// CorePageTabLayout.defaultProps = {}

// CorePageTabLayout.propTypes = {}

export default React.memo(CorePageTabLayout)
