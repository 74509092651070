import React, { useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import ClassRoomDetailProvider from './ClassRoomDetailProvider'
import { useTranslation } from 'react-i18next'
import CorePageTabLayout from '@Core/Components/PageLayout/CorePageTabLayout'
import PageTitle from '@Core/Components/PageLayout/components/PageTitle'
import { Button, CircularProgress, Skeleton } from '@mui/material'
import { ROUTER_WORKSPACE_CLASS_ROOM } from '../../configs/const'
import Student from '../Student'
import { Class } from '@mui/icons-material'
import { useRequest } from 'ahooks'
import adminClassRoomService from '@App/Studio/services/admin/adminClassRoomService'
import ExamTab from './ExamTab'
import ExamResultsTab from './ExamResultsTab'
import ExamHomeworkTab from './ExamHomeworkTab'
import adminStudentService from '@App/Studio/services/admin/adminStudentService'
import ClassroomPageTitle from './ClassroomPageTitle'
import StudentExerciseTable from '../Student/components/StudentExerciseTable'
import adminExamService from '../../../Exam/services/adminExamService'
import adminExamRoomService from '../../../ExamRoom/services/admin/adminExamRoomService'
import { isUserStudent } from '@Core/Helpers/User'
// import PropTypes from 'prop-types'

const ClassRoomDetail = props => {
	const { t } = useTranslation()
	const { id, feature, feature_id } = useParams()
	const { state } = useLocation()
	// console.log('============= state', state)
	const isEdit = id !== 'new'
	const navigate = useNavigate()
	const [search, setSearch] = useSearchParams()
	const viewType = search.get('view')

	const [loading, setLoading] = useState(true)

	const { data: classRoomDetail, loading: loadingClassroom } = useRequest(adminClassRoomService.find, {
		defaultParams: [id],
		cacheKey: `classRoomDetail-${id}`,
		cacheTime: 60 * 60 * 1000,
		staleTime: 60 * 60 * 1000
	})

	const {
		data: student,
		loading: loadingStudent,
		runAsync: fetchStudent,
		mutate: setStudent
	} = useRequest(adminStudentService.find, {
		manual: true,
		cacheKey: feature_id ? `studentDetail-${feature_id}` : undefined,
		cacheTime: 60 * 60 * 1000,
		staleTime: 60 * 60 * 1000
	})

	const {
		data: exam,
		runAsync: fetchExam,
		mutate: setExam
	} = useRequest(adminExamService.find, {
		manual: true,
		cacheKey: feature_id ? `examDetail-${feature_id}` : undefined,
		cacheTime: 60 * 60 * 1000,
		staleTime: 60 * 60 * 1000
	})

	const {
		data: exercise,
		runAsync: fetchExercise,
		mutate: setExercise
	} = useRequest(adminExamRoomService.find, {
		manual: true,
		cacheKey: feature_id ? `exerciseDetail-${feature_id}` : undefined,
		cacheTime: 60 * 60 * 1000,
		staleTime: 60 * 60 * 1000
	})
	// console.log('============= exam', exam)

	const hideLoading = () => {
		setTimeout(() => {
			setLoading(false)
		}, 500)
	}

	const isFeatureStudent = feature === 'student' && feature_id > 0
	const isFeatureExam = feature === 'exam' && feature_id > 0
	const isFeatureExercise = feature === 'exercise' && feature_id > 0

	useEffect(() => {
		setLoading(true)
		// console.log('============= isFeatureExercise', isFeatureExercise, feature_id)
		if (isFeatureStudent) {
			if (!state?.student) {
				fetchStudent(feature_id).then(() => {
					setLoading(false)
				})
			} else {
				setStudent(state.student)
				hideLoading()
			}
		} else if (isFeatureExam) {
			if (!state?.exam) {
				fetchExam(feature_id).then(() => {
					setLoading(false)
				})
			} else {
				setExam(state.exam)
				hideLoading()
			}
		} else if (isFeatureExercise) {
			if (!state?.exercise) {
				fetchExercise(feature_id).then(() => {
					setLoading(false)
				})
			} else {
				setExercise(state.exercise)
				hideLoading()
			}
		} else if (classRoomDetail?.id) {
			hideLoading()
		}
	}, [state, feature, classRoomDetail])

	// console.log('============= classRoomDetail', classRoomDetail)

	const tabs = useMemo(() => {
		// console.log('============= exam', exam)
		return [
			{
				key: 'student',
				label: t('Thành viên'),
				content: <Student class_room_id={id} classRoomDetail={classRoomDetail} />,
				hidden: isFeatureStudent
				// disabled: false
			},
			{
				key: 'exam',
				label: t('Đề thi ôn tập'),
				content: <ExamTab />,
				hidden: isFeatureExam || isFeatureExercise
				// disabled: !isEdit
			},
			{
				key: 'exam-homework',
				label: t('Bài kiểm tra'),
				content: <ExamHomeworkTab />,
				hidden: isFeatureExercise || isUserStudent()
				// disabled: !isEdit
			},
			{
				key: 'results',
				label: t('Danh sách kết quả'),
				content: <ExamResultsTab />,
				hidden: !isFeatureExam
			}
		].filter(i => i.hidden !== true)
	}, [t, id, classRoomDetail, feature_id, exam])

	if (loading || loadingClassroom) {
		return (
			<div className="mt-40 text-center">
				<CircularProgress />
			</div>
		)
	}

	// console.log('============= state?.student', student_id, state?.student)
	const context = {
		feature,
		isFeatureExam,
		isFeatureExercise,
		isFeatureStudent,
		student_id: feature_id,
		student,
		exam,
		exercise
	}

	const renderContentPage = () => {
		if (viewType === 'results') {
			return <ExamResultsTab />
		}
		return (
			<CorePageTabLayout
				tabs={tabs}
				detault={isFeatureStudent ? 'exam' : 'student'}
				tabAction={
					<Button
						variant="contained"
						color="error"
						size="small"
						disableElevation
						onClick={() => {
							let url = ROUTER_WORKSPACE_CLASS_ROOM.root
							if (feature) {
								url = -1
							}
							navigate(url)
						}}
					>
						{t('Trở về')}
					</Button>
				}
			/>
		)
	}

	return (
		<ClassRoomDetailProvider isEdit={isEdit} t={t} classRoomDetail={classRoomDetail} {...context}>
			<ClassroomPageTitle type={isFeatureStudent ? 'student' : 'classroom'} />
			{renderContentPage()}
		</ClassRoomDetailProvider>
	)
}

// ClassRoomDetail.defaultProps = {}

// ClassRoomDetail.propTypes = {}

export default React.memo(ClassRoomDetail)
