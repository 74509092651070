import CoreTable, { columnHelper } from '@Core/Components/Table/CoreTable'
import { renderTime } from '@Core/Helpers/Date'
import { useCorePageContext } from '@Core/Provider/CorePageProvider'
import { Avatar } from '@mui/material'
import React, { useMemo } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import AvgTotalScore from '../../../components/Table/AvgTotalScore'
import ExamResultStatisRank from '@Core/Components/Common/ExamResult/Table/ExamResultStatisRank'
import StudentAction from '../../../components/Table/StudentAction'
// import PropTypes from 'prop-types'

const ExamStudentTable = props => {
	const { tableHandler, t, classRoomDetail, exam, isFeatureExam } = useCorePageContext()
	const [search, setSearch] = useSearchParams()

	const navigate = useNavigate()
	// console.log('============= exam', exam)
	const columns = useMemo(
		() => [
			columnHelper.accessor('fullname', {
				header: t('Thành viên'),
				cell: ({ row, getValue }) => {
					return (
						<div className="flex items-center gap-2">
							<Avatar src={row.original?.user?.info?.avatar_url} className="border border-gray-200" />
							{getValue()}
						</div>
					)
				}
			}),
			columnHelper.accessor('email', {
				header: t('Email')
			}),
			!exam?.id &&
				columnHelper.accessor('total_completed_exam_study', {
					header: t('Đề thi đã ôn')
				}),

			columnHelper.accessor('total_result_study_test', {
				header: t('Lượt ôn tập')
			}),
			columnHelper.accessor('total_time_used.study_test', {
				header: t('Tổng thời gian ôn tập'),
				cell: ({ getValue }) => (getValue() > 0 ? renderTime(getValue()) : null)
			}),
			columnHelper.accessor('total_result_test', {
				header: t('Lượt thi thử')
			}),
			columnHelper.accessor('total_time_used.test', {
				header: t('Tổng thời gian thi thử'),
				cell: ({ getValue }) => (getValue() > 0 ? renderTime(getValue()) : null)
			}),

			columnHelper.accessor(exam?.id ? 'avg_total_score_test' : 'avg_total_score_exercise', {
				header: t('Điểm trung bình'),
				cell: ({ getValue }) => <AvgTotalScore total_score={getValue()} />
			}),
			columnHelper.accessor('statis_rank', {
				header: t('Xếp loại'),
				cell: ({ row, getValue }) => {
					const rank_data = getValue()
					return <ExamResultStatisRank rank_data={rank_data} />
				}
			}),

			columnHelper.accessor('id', {
				header: t('Hành động'),
				cell: ({ getValue, row }) => (
					<StudentAction
						classRoomDetail={classRoomDetail}
						item={row.original}
						exam={exam}
						isFeatureExam={isFeatureExam}
					/>
				)
			})
		],
		[t, exam]
	)

	return <CoreTable isShowPagination columns={columns} {...tableHandler} />
}

// ExamStudentTable.defaultProps = {}

// ExamStudentTable.propTypes = {}

export default React.memo(ExamStudentTable)
