import React from 'react'
import Layout from './Layout'
import CoreAuthProvider from '@Core/Provider/CoreAuthProvider'
import CoreWorkspaceProvider from '@Core/Provider/CoreWorkspaceProvider'
import CreateExamOptionProvider from './Modules/Workspace/Exam/components/CreateExamOptionProvider'
import CoreRouterProvider from '@Core/Provider/CoreRouterProvider'
import { appNavigationConfig, studioRouterConfig } from './appConfig'
import CoreAuthorization from '@Core/Components/CoreAuthorization'
import CreationToolProvider from './Modules/Workspace/Exam/pages/CreateWithAI/Provider/CreationToolProvider'
import CoreContactProvider from '@Core/Provider/CoreContactProvider'
import CorePersonalProvider from '@Core/Provider/CorePersonalProvider'
import { APP_NAME } from '@Core/Components/const'
import { useBreakpoints } from '@Core/hooks'
import Page403 from '@App/CommonPage/ErrorPage/pages/Page403'
import { useTranslation } from 'react-i18next'
import ExamExerciseProvider from '@Core/Components/Common/ExamExercise/ExamExerciseProvider'
import CoreExamQuestionProvider from '@Core/Provider/CoreExamQuestionProvider'

// import PropTypes from 'prop-types'
const maintain = false
const Studio = props => {
	const { mobile } = useBreakpoints()
	const { t } = useTranslation()

	// if (mobile) {
	// 	return (
	// 		<div className="flex flex-col items-center justify-center min-h-screen">
	// 			<Page403 msg="Vui lòng truy cập bằng máy tính để sử dụng những tính năng tuyệt vời của EduQuiz" />
	// 		</div>
	// 	)
	// }

	if (maintain) {
		return (
			<div className="flex flex-col items-center justify-center min-h-screen">
				<Page403 msg="Bảo trì hệ thống, EduQuiz sẽ hoạt động trở lại vào 17:00 29/08/2024 , mong các bạn thông cảm !" />
			</div>
		)
	}

	return (
		<CoreRouterProvider appRouter={studioRouterConfig}>
			<CoreAuthProvider app={APP_NAME.studio}>
				<CoreAuthorization>
					<CoreWorkspaceProvider>
						<CoreExamQuestionProvider>
							<CreateExamOptionProvider>
								<CreationToolProvider>
									<CoreContactProvider>
										<CorePersonalProvider>
											<ExamExerciseProvider>
												<Layout appNavigationConfig={appNavigationConfig} />
											</ExamExerciseProvider>
										</CorePersonalProvider>
									</CoreContactProvider>
								</CreationToolProvider>
							</CreateExamOptionProvider>
						</CoreExamQuestionProvider>
					</CoreWorkspaceProvider>
				</CoreAuthorization>
			</CoreAuthProvider>
		</CoreRouterProvider>
	)
}

// index.defaultProps = {}

// index.propTypes = {}

export default React.memo(Studio)
