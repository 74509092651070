import adminExamService from '@App/Studio/Modules/Workspace/Exam/services/adminExamService'
import adminClassRoomService from '@App/Studio/services/admin/adminClassRoomService'
import CoreDialog from '@Core/Components/Dialog/CoreDialog'
import { successMsg } from '@Core/Helpers/Message'
import { hideLoadingPage, showLoadingPage } from '@Core/Helpers/System'
import {
	Button,
	Checkbox,
	CircularProgress,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	TextField,
	Typography
} from '@mui/material'
import { clearCache, useBoolean, useDebounce, useRequest, useSelections } from 'ahooks'
import { filter, map } from 'lodash'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
// import PropTypes from 'prop-types'

export const useAddExam = ({ tableHandler, classRoomDetail }) => {
	const { t } = useTranslation()
	const [open, { setFalse, setTrue }] = useBoolean(false)

	const {
		data: exams,
		loading,
		run: getExamsAvaiable
	} = useRequest(adminClassRoomService.getExamsAvaiable, {
		manual: true,
		cacheKey: 'getExamsAvaiable',
		cacheTime: 1000 * 60,
		staleTime: 1000 * 60
	})

	const list = useMemo(() => {
		if (exams?.data) {
			return map(exams?.data, i => i.id)
		}
		return []
	}, [loading])
	// console.log('============= list', list)

	const [search, setSearch] = useState()
	const debouncedSearch = useDebounce(search, { wait: 300 })
	const { selected, allSelected, isSelected, toggle, toggleAll, partiallySelected, unSelectAll } = useSelections(
		list,
		{
			defaultSelected: []
		}
	)

	const { run: handleAddExam } = useRequest(adminClassRoomService.addExams, {
		manual: true,
		onBefore: () => {
			showLoadingPage()
		},
		onSuccess: res => {
			tableHandler.handleFetchData()
			successMsg(t('Thêm đề thi ôn tập thành công'))
			clearCache('getExamsAvaiable')
		},
		onFinally: () => {
			hideLoadingPage()
			setFalse()
			unSelectAll()
			setSearch()
		}
	})

	// console.log('============= exams', exams)

	const handleOpen = () => {
		getExamsAvaiable(classRoomDetail?.id, { size: 1000 })
		setTrue()
	}

	// const handleAddExam = () => {
	// 	console.log('============= selected', selected)
	// }

	const renderExams = () => {
		if (loading) {
			return (
				<div className="my-20 text-center">
					<CircularProgress />
				</div>
			)
		}

		const data = filter(exams?.data, i => {
			const checkSearch = debouncedSearch ? i.name.indexOf(debouncedSearch) > -1 : true
			// if (selected.includes(i.id)) {
			// 	return true
			// }
			return checkSearch || selected.includes(i.id)
		})

		if (data.length === 0) {
			return (
				<div className="my-20 text-center">
					<Typography>{t('Không tìm thấy đề thi nào!')}</Typography>
				</div>
			)
		}

		const el = map(data, item => {
			const labelId = `checkbox-list-label-${item.id}`
			return (
				<ListItem key={item.id} disablePadding>
					<ListItemButton
						role={undefined}
						// onClick={handleToggle(value)}
						onClick={() => toggle(item.id)}
						dense
					>
						<ListItemIcon>
							<Checkbox
								edge="start"
								// checked={checked.indexOf(value) !== -1}
								checked={isSelected(item.id)}
								// onClick={() => toggle(item.id)}
								tabIndex={-1}
								disableRipple
								inputProps={{ 'aria-labelledby': labelId }}
							/>
						</ListItemIcon>
						<ListItemText
							id={labelId}
							primary={item.name}
							secondary={t('{{number}} câu hỏi', { number: item.total_question })}
						/>
					</ListItemButton>
				</ListItem>
			)
		})

		return (
			<div>
				<List sx={{ width: '100%' }}>{el}</List>
			</div>
		)
	}

	const renderAddExamDialog = () => {
		const data = filter(exams?.data, i => {
			const checkSearch = debouncedSearch ? i.name.indexOf(debouncedSearch) > -1 : true
			// if (selected.includes(i.id)) {
			// 	return true
			// }
			return checkSearch || selected.includes(i.id)
		})

		return (
			<CoreDialog
				open={open}
				handleClose={setFalse}
				dialogTitle={
					<div className="flex flex-col gap-2">
						<Typography variant="SB16">{t('Thêm đề thi ôn tập cho lớp học')}</Typography>
						<div className="flex flex-col gap-1 text-left">
							<div className="flex justify-between">
								<Typography variant="M14">{t('Đề thi của tôi')}</Typography>
								<Typography variant="M14">{t('Tổng: {{total}}', { total: data.length })}</Typography>
							</div>
							<TextField
								fullWidth
								size="small"
								placeholder={t('Tìm kiếm đề thi')}
								onChange={e => setSearch(e.target.value)}
							/>
						</div>
					</div>
				}
				dialogContent={renderExams()}
				maxWidth="sm"
				dialogAction={
					<Button
						variant="contained"
						gradient="primary"
						fullWidth
						disabled={selected.length < 1}
						onClick={() => handleAddExam(classRoomDetail?.id, { exam_ids: selected })}
					>
						{t('Xác nhận')}
					</Button>
				}
			/>
		)
	}

	return {
		handleOpen,
		renderAddExamDialog
	}
}

// useAddExam.defaultProps = {}

// useAddExam.propTypes = {}
