import { useConfirm } from '@Core/Components/Confirm/CoreConfirm'
import { Box, Button, Collapse } from '@mui/material'
import React from 'react'
import { useForm } from 'react-hook-form'
import { BiTrash } from 'react-icons/bi'
import { useCoreTableContext } from '../CoreTable'
// import PropTypes from 'prop-types'
// import {} from 'lodash'
const CoreTableToolbar = props => {
	const { handleFetchData, t } = props
	const { rowSelection, table, onConfirmMassDelete, hasMassDelete } = useCoreTableContext()
	const confirm = useConfirm()
	const selectedRowData = table.getSelectedRowModel().flatRows.map(row => row.original)

	const handleMassDelete = () => {
		confirm({
			content: t('table.mass_delete_confirm'),
			onOk: () => onConfirmMassDelete(selectedRowData),
			zIndex: 9999
		})
	}

	return (
		<Collapse in={hasMassDelete && Object.keys(rowSelection).length > 0}>
			<Box className="mx-8 my-4">
				<Button
					variant="contained"
					size="small"
					color="warning"
					startIcon={<BiTrash />}
					onClick={handleMassDelete}
				>
					{t('btn.mass_delete')}
				</Button>
			</Box>
		</Collapse>
	)
}

// CoreTableToolbar.defaultProps = {}

// CoreTableToolbar.propTypes = {}

export default React.memo(CoreTableToolbar)
