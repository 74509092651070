import { Chip, Typography } from '@mui/material'
import React from 'react'
// import PropTypes from 'prop-types'

const RankLabel = ({ rank }) => {
	return (
		<Chip
			sx={{
				fontWeight: 500,
				backgroundColor: rank?.background_color,
				color: rank?.text_color
			}}
			label={rank?.name}
		/>
	)
}

// RankLabel.defaultProps = {}

// RankLabel.propTypes = {}

export default React.memo(RankLabel)
