import { Skeleton, Typography } from '@mui/material'
import React from 'react'
// import PropTypes from 'prop-types'

const TotalData = ({ title, totalData, loading }) => {
	if (loading) {
		return (
			<div className="mt-2">
				<Skeleton width={100} />
			</div>
		)
	}
	return (
		<div className="flex items-center gap-2 min-w-[100px]">
			<Typography color="primary" variant="M18">
				{totalData}
			</Typography>
			<Typography className="w-max text-label" variant="M16">
				{title}
			</Typography>
		</div>
	)
}

// TotalData.defaultProps = {}

// TotalData.propTypes = {}

export default React.memo(TotalData)
