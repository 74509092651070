import { Box, Tooltip, Typography } from '@mui/material'
import clsx from 'clsx'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { MdAnalytics } from 'react-icons/md'
// import PropTypes from 'prop-types'

const ExamIconViewed = ({ className, exam }) => {
	const { t } = useTranslation()
	return (
		<Tooltip title={t('Lượt xem')} placement="bottom">
			<Box
				sx={{
					// ml: 3,
					display: 'flex',
					alignItems: 'center',
					'& .MuiSvgIcon-root': {
						fontSize: 16
					}
				}}
				className={clsx('gap-2', className)}
			>
				<MdAnalytics className="text-blue-600" />
				<Typography variant="M14">{exam?.viewed ?? 0}</Typography>
			</Box>
		</Tooltip>
	)
}

// ExamIconViewed.defaultProps = {}

// ExamIconViewed.propTypes = {}

export default React.memo(ExamIconViewed)
