import { Box, useTheme } from '@mui/material'
import clsx from 'clsx'
import React, { useMemo } from 'react'
import { useBreakpoints } from '@Core/hooks'
import { useQuizExamRoomContext } from '@App/Quiz/Modules/Exam/pages/ExamRoom/QuizExamRoomProvider'

import { useLocation } from 'react-router-dom'
import LogoBusiness from '@App/Quiz/Layout/AppHeader/LogoBusiness'
// import PropTypes from 'prop-types'

const AppHeader = props => {
	const theme = useTheme()
	const { pathname } = useLocation()
	const { laptop } = useBreakpoints()
	const { exam_room, getExamRoomConfig } = useQuizExamRoomContext()

	const isBusiness = useMemo(() => {
		return pathname.indexOf('/quiz/room') > -1
	}, [pathname])

	return (
		<Box
			className={clsx(
				'w-screen  p-2 md:px-6  sticky top-0 shadow-md z-[99] h-[60px] md:h-[80px] flex item-center justify-between',
				{
					// hidden: !layoutConfig?.header?.display
					// (mobile && !routeSettings?.layout?.config?.mobileHeader?.display)
				}
			)}
			sx={{
				backgroundColor: theme.palette.background.main
			}}
		>
			<LogoBusiness />
		</Box>
	)
}

// AppHeader.defaultProps = {}

// AppHeader.propTypes = {}

export default React.memo(AppHeader)
