import { useCoreAuth, useCoreAuthActions } from '@Core/Provider/CoreAuthProvider'
import { Logout } from '@mui/icons-material'
import { Button } from '@mui/material'
import React from 'react'
// import PropTypes from 'prop-types'

const LogoutButton = props => {
	const { user, isLoading, isAuthenticated } = useCoreAuth()
	const { logout } = useCoreAuthActions()

	if (!isAuthenticated) return null

	return (
		<Button startIcon={<Logout />} onClick={logout}>
			Đăng xuất
		</Button>
	)
}

// LogoutButton.defaultProps = {}

// LogoutButton.propTypes = {}

export default React.memo(LogoutButton)
