import { useCorePageContext } from '@Core/Provider/CorePageProvider'
import { AccountBox, Checklist, Person4, Quiz, School } from '@mui/icons-material'
import { Avatar, Box, Tooltip, Typography } from '@mui/material'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { SiGoogleclassroom } from 'react-icons/si'
import { useNavigate } from 'react-router-dom'
import { ROUTER_WORKSPACE_CLASS_ROOM } from '../../configs/const'
import { upperFirst } from 'lodash'
// import PropTypes from 'prop-types'

const ClassroomPageTitle = ({ type }) => {
	const { t } = useTranslation()
	const { classRoomDetail, student, isFeatureExam, isFeatureStudent, isFeatureExercise, feature, exam, exercise } =
		useCorePageContext()
	// console.log('============= isFeatureExam', isFeatureExam)
	const navigate = useNavigate()
	const color = useMemo(() => {
		const data = {
			classroom: 'primary'
		}
		if (isFeatureStudent) {
			data.classroom = 'textSecondary'
			data.student = 'primary'
		}
		if (isFeatureExam) {
			data.classroom = 'textSecondary'
			data.exam = 'primary'
		}
		if (isFeatureExercise) {
			data.classroom = 'textSecondary'
			data.exercise = 'primary'
		}
		// if (examRoomDetail?.id) {
		// 	data.room = 'primary'
		// 	// data.stage =
		// 	return data
		// }
		// if (examStageDetail?.id && type === 'examStageDetail') {
		// 	data.stage = 'primary'
		// }
		return data
	}, [feature])

	const navigateClassroom = () => {
		if (feature) navigate(`${ROUTER_WORKSPACE_CLASS_ROOM.detail(classRoomDetail?.id)}?tab=student`)
	}

	// console.log('============= exercise', exercise)
	return (
		<Box
			// component="h2"
			variant="h2"
			sx={{
				fontSize: 20,
				color: 'text.primary',
				fontWeight: 600,
				mb: 2
			}}
			className="flex items-center gap-5"
		>
			{classRoomDetail?.id && (
				<Tooltip title={t('Lớp học tập')}>
					<div className="flex items-center gap-2">
						<School
							color={color?.classroom}
							sx={{ fontSize: 50 }}
							className="cursor-pointer hover:text-primary"
							onClick={navigateClassroom}
						/>
						<div className="flex flex-col">
							<Typography
								variant="SB18"
								color={color?.classroom}
								className="cursor-pointer hover:text-primary"
								onClick={navigateClassroom}
							>
								{classRoomDetail?.name}
							</Typography>
							{/* <div className="flex items-center gap-2">
							<Typography variant="M14" color="textSecondary">{`${formatDate(
								examStageDetail?.start_date
							)} - ${formatDate(examStageDetail?.end_date)}`}</Typography>
							<StageRoomStatusLabel status={examStageDetail?.status} />
						</div> */}
						</div>
					</div>
				</Tooltip>
			)}

			{isFeatureStudent && (
				<Tooltip title={t('Thành viên')}>
					<div className="flex items-center gap-2">
						{/* <AccountBox color={color?.student} sx={{ fontSize: 50 }} /> */}
						<Avatar
							sx={{ width: 50, height: 50 }}
							src={student?.user?.info?.avatar_url ?? student?.avatar_url}
							className="border border-gray-200"
						/>

						<div className="flex flex-col">
							<Typography variant="SB18" color={color?.student}>
								{student?.fullname}
							</Typography>
							<div className="flex items-center gap-2">
								<Typography variant="M14" color="textSecondary">
									{student?.email}
								</Typography>
								{/* <StageRoomStatusLabel status={examRoomDetail?.status} /> */}
							</div>
						</div>
					</div>
				</Tooltip>
			)}
			{isFeatureExam && (
				<Tooltip title={t('Đề ôn tập')}>
					<div className="flex items-center gap-2">
						<Quiz color={color?.exam} sx={{ fontSize: 50 }} />

						<div className="flex flex-col">
							<Typography variant="SB18" color={color?.exam}>
								{upperFirst(exam?.name)}
							</Typography>
						</div>
					</div>
				</Tooltip>
			)}

			{isFeatureExercise && (
				<Tooltip title={t('Bài kiểm tra')}>
					<div className="flex items-center gap-2">
						<Checklist color={color?.exercise} sx={{ fontSize: 50 }} />

						<div className="flex flex-col">
							<Typography variant="SB18" color={color?.exam}>
								{t('Bài kiểm tra - {{name}}', { name: exercise?.exam?.name })}
							</Typography>
						</div>
					</div>
				</Tooltip>
			)}
		</Box>
	)
}

// ClassroomPageTitle.defaultProps = {}

// ClassroomPageTitle.propTypes = {}

export default React.memo(ClassroomPageTitle)
