import ExamGridLayout from '@Core/Components/Grid/ExamGridLayout'
import CorePageLayout from '@Core/Components/PageLayout/CorePageLayout'
import CorePageProvider, { useCorePageContext } from '@Core/Provider/CorePageProvider'
import { memo, useEffect, useState } from 'react'
import ExamHomeworkFilter from './ExamHomeworkFilter'
import examSettingService from '@App/Studio/Modules/Workspace/Exam/services/examSettingService'
import { useRequest } from 'ahooks'
import {
	CoreActionDelete,
	CoreActionEdit,
	CoreActionIcon,
	CoreActionResult
} from '@Core/Components/Table/components/CoreTableAction'
import ExamHomeworkGroup from '@Core/Components/Common/ExamHomeworkGroup'
import { useCorePageTabContext } from '@Core/Components/PageLayout/CorePageTabLayout'
import { IoShareSocial } from 'react-icons/io5'
import { errorMsg, successMsg } from '@Core/Helpers/Message'
import { EXAM_ROOM } from '@Core/Components/const'
import adminExamRoomService from '@App/Studio/Modules/Workspace/ExamRoom/services/admin/adminExamRoomService'
import useCoreTable from '@Core/Components/Table/hooks/useCoreTable'
import ExamExerciseTable from './ExamExerciseTable'
import StudentExerciseTable from '../../Student/components/StudentExerciseTable'

const ExamHomeworkTab = props => {
	const { classRoomDetail, t, student, isFeatureStudent } = useCorePageContext()
	// console.log('============= student', student)

	const requestExamExercise = useRequest(examSettingService.getExamHomeworkClassroom, {
		manual: true
	})

	const tableHandler = useCoreTable(requestExamExercise)

	useEffect(() => {
		const params = {
			type: 'exercise',
			// user_id: student?.user_id,
			class_room_id: classRoomDetail?.id
		}
		if (isFeatureStudent) {
			params.user_id = student?.user_id
		}
		tableHandler.handleFetchData(params)
	}, [])

	const handleDeleteExamHomework = async id => {
		try {
			const res = await adminExamRoomService.delete(id)
			successMsg(t('Xóa thành công'))
			tableHandler.handleFetchData({
				type: 'exercise'
			})
		} catch (err) {
			errorMsg(t('Xóa thất bại'))
		}
	}

	const pageContext = { tableHandler, t, handleDeleteExamHomework, student, classRoomDetail, ...props }

	return (
		<CorePageProvider {...pageContext} t={t}>
			<CorePageLayout toolbar={<ExamHomeworkFilter />}>
				{isFeatureStudent ? <StudentExerciseTable /> : <ExamExerciseTable />}
			</CorePageLayout>
		</CorePageProvider>
	)
}

export default memo(ExamHomeworkTab)
