import { QUIZ_ROUTER_EXAM } from '@App/Quiz/Modules/Exam/configs/const'
import { ROUTER_PERSONAL_RESULT_EXAM_EXERCISE } from '@App/Studio/Modules/Personal/ResultExamExercise/configs/const'
import ExamResultStatisQuestionLabel from '@Core/Components/Common/ExamResult/Table/ExamResultStatisQuestionLabel'
import ExamResultStatisRank from '@Core/Components/Common/ExamResult/Table/ExamResultStatisRank'
import ExamResultStatusLabel from '@Core/Components/Common/ExamResult/Table/ExamResultStatusLabel'
import StageRoomStatusLabel from '@Core/Components/Common/ExamRoom/StageRoomStatusLabel'
import CoreTable, { columnHelper } from '@Core/Components/Table/CoreTable'
import { renderTime } from '@Core/Helpers/Date'
import { formatDate } from '@Core/Helpers/String'
import { useCorePageContext } from '@Core/Provider/CorePageProvider'
import { Button, Chip, Typography } from '@mui/material'
import { getValue } from '@mui/system'
import { Gauge, gaugeClasses } from '@mui/x-charts'
import React, { useMemo } from 'react'
import urlcat from 'urlcat'
import AvgTotalScore from '../../../components/Table/AvgTotalScore'
import { CoreActionIcon } from '@Core/Components/Table/components/CoreTableAction'
import { FactCheck } from '@mui/icons-material'
// import PropTypes from 'prop-types'

const StudentExerciseTable = props => {
	const { tableHandler, t, handleDeleteExamHomework, student } = useCorePageContext()

	// const navigate = useNavigate()

	// const confirm = useConfirm()

	const columns = useMemo(
		() => [
			columnHelper.accessor('exam.name', {
				header: t('Bài kiểm tra')
			}),
			columnHelper.accessor('status', {
				header: t('Trạng thái'),
				cell: ({ getValue }) => <StageRoomStatusLabel status={getValue()} />
			}),
			columnHelper.accessor('start_date', {
				header: t('Thời gian bắt đầu'),
				cell: ({ getValue }) => (
					<p className="whitespace-nowrap">{formatDate(getValue(), 'HH:mm DD-MM-YYYY')}</p>
				)
			}),
			columnHelper.accessor('end_date', {
				header: t('Thời gian nộp bài'),
				cell: ({ getValue }) => (
					<p className="whitespace-nowrap">{formatDate(getValue(), 'HH:mm DD-MM-YYYY')}</p>
				)
			}),
			columnHelper.accessor('exam_config.exam_test_time', {
				header: t('Thời gian làm bài'),
				cell: ({ row, getValue }) => (
					<Typography variant="M14">{t('{{time}} phút', { time: getValue() })}</Typography>
				)
			}),
			// columnHelper.accessor('status', {
			// 	header: t('Trạng thái'),
			// 	cell: ({ getValue }) => <StageRoomStatusLabel status={getValue()} />
			// }),
			// columnHelper.accessor('total_question', {
			// 	// 	header: t('Tổng câu hỏi')
			// }),
			columnHelper.accessor('results_count', {
				header: t('Trạng thái hoàn thành'),
				cell: ({ row, getValue }) => <ExamResultStatusLabel status={getValue() > 0 ? 'COMPLETED' : 'TODO'} />
			}),
			// columnHelper.accessor('result.start_date', {
			// 	header: t('Bắt đầu làm bài'),
			// 	cell: ({ getValue }) => (
			// 		<p className="whitespace-nowrap">{formatDate(getValue(), 'HH:mm DD-MM-YYYY')}</p>
			// 	)
			// }),
			// columnHelper.accessor('result.end_date', {
			// 	header: t('Kết thúc vào lúc'),
			// 	cell: ({ getValue }) => (
			// 		<p className="whitespace-nowrap">{formatDate(getValue(), 'HH:mm DD-MM-YYYY')}</p>
			// 	)
			// }),
			columnHelper.accessor('result.total_time_used', {
				header: t('Thời gian hoàn thành'),
				cell: ({ row, getValue }) => (getValue() > 0 ? renderTime(getValue()) : null)
			}),
			columnHelper.accessor('result', {
				header: t('Thống kê câu hỏi'),
				cell: ({ getValue }) => (getValue() ? <ExamResultStatisQuestionLabel result={getValue()} /> : null)
			}),
			columnHelper.accessor('result.total_score', {
				header: t('Điểm số'),
				cell: ({ getValue }) => <AvgTotalScore total_score={getValue()} />
			}),
			columnHelper.accessor('statis_rank', {
				header: t('Xếp loại'),
				cell: ({ row, getValue }) => {
					const rank_data = getValue()
					return <ExamResultStatisRank rank_data={rank_data} />
				}
			}),
			columnHelper.accessor('result.id', {
				header: t('Hành động'),
				cell: ({ getValue }) => {
					return (
						<Button
							variant="contained"
							className="whitespace-nowrap"
							onClick={() => {
								window.open(
									`${window.location.origin}/quiz/${QUIZ_ROUTER_EXAM.admin_result(getValue())}`,
									'_blank'
								)
							}}
						>
							{t('Xem chi tiết')}
						</Button>
					)
				}
			})
		],
		[t]
	)

	return <CoreTable isShowPagination columns={columns} {...tableHandler} />
}

// StudentExerciseTable.defaultProps = {}

// StudentExerciseTable.propTypes = {}

export default React.memo(StudentExerciseTable)
