import { Card, CardContent, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
// import PropTypes from 'prop-types'

const PageHeader = ({ children }) => {
	return (
		<Box
			sx={{
				height: 60,
				display: 'flex',
				alignItems: 'center',
				borderBottom: theme => `1px solid ${theme.palette.divider}`,
				padding: {
					xs: '4px 10px',
					xl: '12px 10px'
				}
			}}
			className="gap-4 apps-header"
		>
			{children}
		</Box>
	)
}

const CorePageLayout = ({
	pageTitle,
	toolbar,
	headerAction,
	children,
	cardStyle,
	contentStyle,
	beforeContent,
	showHeader = true
}) => {
	return (
		<Card sx={{ display: 'flex', flexDirection: 'column', borderRadius: 2, ...cardStyle }}>
			{showHeader && (
				<PageHeader>
					{pageTitle && <Typography variant="M18">{pageTitle}</Typography>}

					{toolbar}
					{headerAction && <div className="ml-auto flex gap-2">{headerAction}</div>}
				</PageHeader>
			)}

			{beforeContent}
			<CardContent
				sx={{
					px: 0,
					height: '100%',
					'&:last-of-type': {
						pb: 4
					},
					...contentStyle
				}}
			>
				{children}
			</CardContent>
		</Card>
	)
}

// CorePageLayout.defaultProps = {}

// CorePageLayout.propTypes = {}

export default React.memo(CorePageLayout)
