import { formatAppRouter } from '@Core/Helpers/System'
import { BUSINESS_ROUTER } from './const'
import React from 'react'

const LazyPricing = React.lazy(() => import('./Pages/Pricing'))

export const businessRouterConfig = formatAppRouter([
	{
		auth: [],
		settings: {
			layout: {
				config: {
					header: {
						display: true
					}
				}
			}
		},
		routes: [
			{
				path: `${BUSINESS_ROUTER.pricing}`,
				exact: true,
				element: <LazyPricing />
			}
		]
	}
])
