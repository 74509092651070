import examStageService from '@App/Studio/Modules/Workspace/Exam/services/examStageService'
import CorePageLayout from '@Core/Components/PageLayout/CorePageLayout'
import CorePageProvider, { useCorePageContext } from '@Core/Provider/CorePageProvider'
import { useRequest } from 'ahooks'
import { memo, useCallback, useEffect, useMemo } from 'react'
import ExamResultTable from './ExamResultTable'
import useCoreTable from '@Core/Components/Table/hooks/useCoreTable'
import { useTranslation } from 'react-i18next'
import ExamResultFilterTab from './ExamResultFilterTab'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Button, Typography } from '@mui/material'
import { EXAM_MODE_EXERCISE } from '@Core/Components/const'
import { getQueryUrlObject } from '@Core/Helpers/Url'
import { get } from 'lodash'
import PageTitle from '@Core/Components/PageLayout/components/PageTitle'

const ExamResultsTab = props => {
	const { t } = useTranslation()
	const { classRoomDetail, isFeatureExam, exam, isFeatureStudent, student } = useCorePageContext()
	const navigate = useNavigate()
	const getExamsResult = useRequest(examStageService.getExamResults, {
		manual: true
	})

	const tableHandler = useCoreTable(getExamsResult)

	useEffect(() => {
		const query = getQueryUrlObject()
		const params = {
			class_room_id: classRoomDetail?.id
		}
		// for isFeatureExam
		if (isFeatureExam && exam?.id) {
			params.exam_id = exam.id
		}
		if (query?.user_id) {
			params.user_id = query.user_id
		}

		// for isFeatureStudent
		if (isFeatureStudent && student?.id) {
			params.user_id = student.user_id
		}
		if (query?.exam_id) {
			params.exam_id = query.exam_id
		}
		tableHandler.handleFetchData(params)
		// if (location.state) {
		// 	tableHandler.handleFetchData({ class_room_id: id, student_id: location.state.id, mode: EXAM_MODE_EXERCISE })
		// } else {
		// 	tableHandler.handleFetchData({ class_room_id: id, mode: EXAM_MODE_EXERCISE })
		// }
	}, [])

	const user = useMemo(() => {
		const query = getQueryUrlObject()
		let data = {}
		if (query?.user_id) {
			data = get(tableHandler, 'data[0].user')
		}
		return data
	}, [tableHandler])

	const examByResult = useMemo(() => {
		const query = getQueryUrlObject()
		let data = {}
		if (query?.exam_id) {
			data = get(tableHandler, 'data[0].exam')
		}
		return data
	}, [tableHandler])

	// console.log('============= examByResult', examByResult)

	const pageContext = {
		tableHandler,
		isFeatureExam,
		exam,
		user,
		examByResult,
		isFeatureStudent,
		...props
	}

	return (
		<CorePageProvider {...pageContext} t={t}>
			{isFeatureStudent && (
				<div className="flex items-center justify-between">
					<PageTitle title={t('Danh sách kết quả')} />
					<Button
						variant="contained"
						color="error"
						size="small"
						disableElevation
						onClick={() => {
							navigate(-1)
						}}
					>
						{t('Trở về')}
					</Button>
				</div>
			)}

			<CorePageLayout toolbar={<ExamResultFilterTab />}>
				<ExamResultTable />
			</CorePageLayout>
		</CorePageProvider>
	)
}

export default memo(ExamResultsTab)
