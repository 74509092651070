import { userRole } from '@App/Studio/app-config/const'

import { lazy } from 'react'
import { ROUTER_WORKSPACE_CLASS_ROOM } from './const'
import { ROUTER_LMS_CLASSROOM } from '@App/Lms/consts'
import ClassRoomDetail from '../pages/ClassRoomDetail'

const ClassRoomListLazy = lazy(() => import('../pages/ClassRoomList'))
// const ClassRoomDetailLazy = lazy(() => import('../pages/ClassRoomDetail'))
const ClassroomExercises = lazy(() => import('@App/Lms/admin/pages/Exercise/ListExercise'))

export const workspaceClassRoomRouterConfig = {
	auth: [userRole.admin],
	settings: {
		layout: {
			config: {
				sidebar: {
					display: true
				},
				header: {
					display: true
				}
			}
		}
	},
	routes: [
		{
			path: ROUTER_LMS_CLASSROOM.admin.exercises,
			exact: true,
			element: <ClassroomExercises />
		},
		{
			path: ROUTER_WORKSPACE_CLASS_ROOM.root,
			exact: true,
			element: <ClassRoomListLazy />
		},
		{
			path: ROUTER_WORKSPACE_CLASS_ROOM.detail(),
			exact: true,
			element: <ClassRoomDetail />,
			customLayout: {
				config: {
					sidebar: {
						collapsed: true
					}
				}
			}
		},
		{
			path: ROUTER_WORKSPACE_CLASS_ROOM.feature_detail,
			exact: true,
			element: <ClassRoomDetail />,
			customLayout: {
				config: {
					sidebar: {
						collapsed: true
					}
				}
			}
		}
	]
}
