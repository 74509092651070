import { QUIZ_ROUTER_EXAM } from '@App/Quiz/Modules/Exam/configs/const'
import { ROUTER_WORKSPACE_EXAM } from '@App/Studio/Modules/Workspace/Exam/configs/const'
import ExamResultStatisQuestionLabel from '@Core/Components/Common/ExamResult/Table/ExamResultStatisQuestionLabel'
import CoreTable, { columnHelper } from '@Core/Components/Table/CoreTable'
import { CoreActionView } from '@Core/Components/Table/components/CoreTableAction'
import { renderTime } from '@Core/Helpers/Date'
import { useCorePageContext } from '@Core/Provider/CorePageProvider'
import { Avatar, Button, Typography } from '@mui/material'
import moment from 'moment'
import { memo, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { MdLaptopMac, MdOutlinePhoneIphone } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'

const ExamResultTable = props => {
	const { tableHandler, t, exam, user, student, isFeatureStudent } = useCorePageContext()
	const navigate = useNavigate()
	// console.log('============= exam', exam)
	const columns = useMemo(
		() => [
			columnHelper.accessor('mode', {
				header: t('Chế độ thi'),
				cell: ({ getValue }) => <Typography variant="M14">{t(`mode_exam.${getValue()}`)}</Typography>
			}),
			(!exam?.id || isFeatureStudent) &&
				columnHelper.accessor('exam.name', {
					header: t('Bài tập'),
					cell: ({ getValue }) => (
						<Typography
							className="inline-block w-max"
							sx={{ fontWeight: 400 }}
							component="span"
							variant="M14"
						>
							{getValue()}
						</Typography>
					)
				}),
			!user?.id &&
				!isFeatureStudent &&
				columnHelper.accessor('user.info.fullname', {
					header: t('Thành viên'),
					cell: ({ row, getValue }) => {
						return (
							<div className="flex items-center gap-2">
								<Avatar src={row.original?.user?.info?.avatar_url} className="border border-gray-200" />
								{getValue()}
							</div>
						)
					}
				}),

			!user?.id &&
				!isFeatureStudent &&
				columnHelper.accessor('user.email', {
					header: t('Email'),
					cell: ({ getValue }) => (
						<Typography sx={{ fontWeight: 400 }} component="span" variant="M14">
							{getValue()}
						</Typography>
					)
				}),

			columnHelper.accessor('total_score', {
				header: t('Điểm số'),
				cell: ({ getValue }) => (
					<Typography className="block text-center" sx={{ fontWeight: 400 }} component="span" variant="M14">
						{getValue()}
					</Typography>
				)
			}),
			columnHelper.accessor('rank_data', {
				header: t('Xếp loại'),
				cell: ({ getValue }) => {
					const rank = getValue()
					return (
						<Typography
							className="inline-block px-2 py-1 rounded font-500"
							sx={{
								fontWeight: 400,
								backgroundColor: rank?.background_color,
								color: rank?.text_color,
								minWidth: 'max-content'
							}}
							component="span"
							variant="M12"
						>
							{rank?.name}
						</Typography>
					)
				}
			}),
			columnHelper.accessor('id', {
				header: t('Thống kê câu hỏi'),
				cell: ({ row }) => <ExamResultStatisQuestionLabel result={row.original} />
			}),
			columnHelper.accessor('total_time_used', {
				header: t('Thời gian hoàn thành'),
				cell: ({ getValue }) => (
					<Typography sx={{ fontWeight: 400 }} component="p" variant="M14" className="w-full text-center">
						{renderTime(getValue())}
					</Typography>
				)
			}),
			columnHelper.accessor('end_date', {
				header: t('Thời gian nộp bài thi'),
				cell: ({ row, getValue }) =>
					getValue() ? (
						<div className="flex items-center w-max">
							{moment(getValue()).format('HH:mm DD-MM-YYYY')}
							<span className="ml-1">
								{row.original.platform === 'web' ? (
									<MdLaptopMac className="text-[1.2rem]" />
								) : (
									<MdOutlinePhoneIphone className="text-[1.2rem]" />
								)}
							</span>
						</div>
					) : null
			}),

			columnHelper.accessor('id', {
				header: t('Hành động'),
				cell: ({ getValue }) => {
					return (
						<Button
							className="w-max"
							variant="contained"
							color="primary"
							size="small"
							onClick={() => {
								window.open(
									`${window.location.origin}/quiz/${QUIZ_ROUTER_EXAM.admin_result(getValue())}`,
									'_blank'
								)
							}}
						>
							{t('Xem chi tiết')}
						</Button>
					)
				}
			})
		],
		[t, exam, isFeatureStudent]
	)

	return <CoreTable isShowPagination columns={columns} {...tableHandler} />
}

export default memo(ExamResultTable)
