import { Chip, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
// import PropTypes from 'prop-types'

const ExamResultStatisRank = ({ rank_data }) => {
	const { t } = useTranslation()
	if (Array.isArray(rank_data) && rank_data.length > 0) {
		const el = rank_data.map((item, index) => {
			return (
				<div className="flex flex-col items-center gap-2" key={index}>
					<Chip
						label={item?.name}
						size="small"
						// avatar={<Avatar>{item?.count}</Avatar>}
						sx={{
							background: item?.background_color,
							color: item?.text_color,
							fontWeight: 500
						}}
					/>
					<Typography variant="SB14">{item?.count}</Typography>
				</div>
			)
		})
		return <div className="flex gap-2">{el}</div>
	}
	return <Typography variant="M14">{t('Chưa có kết quả')}</Typography>
}

// ExamResultStatisRank.defaultProps = {}

// ExamResultStatisRank.propTypes = {}

export default React.memo(ExamResultStatisRank)
