import { Snackbar } from '@mui/material'
import { useBoolean, useSet, useUpdateEffect } from 'ahooks'
import React, { useEffect, useState } from 'react'
import SaveQuestionDraft from './SaveQuestionDraft'
import { successMsg } from '@Core/Helpers/Message'
// import PropTypes from 'prop-types'

const ExamQuestionContext = React.createContext({
	handleOpenProgress: () => {},
	setSectionQuestionDraft: () => {},
	handleClose: () => {},
	open: false
})

export const useCoreExamQuestionContext = () => React.useContext(ExamQuestionContext)

const CoreExamQuestionProvider = props => {
	const [open, { setFalse, setTrue }] = useBoolean(false)
	// const [exam, setExam] = useState({})
	// const [sectionDraft, setSectionDraft] = useState({})
	// console.log('============= sectionDraft', sectionDraft)
	const [sectionQuestionDraft, setSectionQuestionDraft] = useState({})
	const [sectionDone, { add: addSectionDone, reset }] = useSet()

	const handleOpenProgress = () => {
		// setExam(exam)
		// setSectionDraft(prev => {
		// 	return {
		// 		...prev,
		// 		[section.id]: section
		// 	}
		// })
		setTrue()
	}

	const handleClose = () => {
		setFalse()
	}

	useUpdateEffect(() => {
		// console.log('============= sectionDone', sectionDone)
		if (sectionDone.size === Object.keys(sectionQuestionDraft).length) {
			successMsg('Lưu toàn bộ câu hỏi thành')
			setTimeout(() => {
				handleClose()
			}, 2000)
		}
	}, [sectionDone])

	const renderSaveQuestionProgress = () => {
		return (
			<Snackbar
				open={open}
				// autoHideDuration={6000}
				// onClose={setFalse}
				// message="Note archived"
				anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
				// action={action}
			>
				<div>
					<SaveQuestionDraft sections={sectionQuestionDraft} />
				</div>
			</Snackbar>
		)
	}

	const context = {
		handleOpenProgress,
		setSectionQuestionDraft,
		handleClose,
		open,
		addSectionDone
	}

	return (
		<ExamQuestionContext.Provider value={context}>
			{props.children}
			{renderSaveQuestionProgress()}
		</ExamQuestionContext.Provider>
	)
}

// CoreExamQuestionProvider.defaultProps = {}

// CoreExamQuestionProvider.propTypes = {}

export default React.memo(CoreExamQuestionProvider)
