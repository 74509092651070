import { CoreActionDelete, CoreActionIcon } from '@Core/Components/Table/components/CoreTableAction'
import { Analytics, FactCheck } from '@mui/icons-material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import urlcat from 'urlcat'
import { ROUTER_WORKSPACE_CLASS_ROOM } from '../../configs/const'
import { useNavigate, useSearchParams } from 'react-router-dom'
// import PropTypes from 'prop-types'

const StudentAction = ({ classRoomDetail, item, isFeatureExam, exam, isFeatureStudent, handleClickDelete }) => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const [search, setSearch] = useSearchParams()
	return (
		<div className="flex gap-2">
			<CoreActionIcon
				icon={<Analytics color="success" />}
				titleTooltip={t('Xem thành tích')}
				onClick={() => {
					const url = urlcat(ROUTER_WORKSPACE_CLASS_ROOM.feature_detail, {
						id: classRoomDetail?.id,
						feature: 'student',
						feature_id: item?.id,
						tab: 'exam'
					})
					navigate(url, {
						state: {
							student: item,
							classroom: classRoomDetail
						}
					})
				}}
			/>
			{exam?.id && isFeatureExam && (
				<CoreActionIcon
					icon={<FactCheck color="primary" />}
					titleTooltip={t('Xem kết quả')}
					onClick={() => {
						setSearch({ tab: 'results', user_id: item.user_id })
					}}
				/>
			)}
			{isFeatureStudent && (
				<CoreActionDelete
					content={t('Bạn có muốn xoá thành viên này không?')}
					onConfirmDelete={async () => {
						await handleClickDelete(item)
					}}
				/>
			)}
		</div>
	)
}

// StudentAction.defaultProps = {}

// StudentAction.propTypes = {}

export default React.memo(StudentAction)
