import CorePageLayout from '@Core/Components/PageLayout/CorePageLayout'
import { PersonAdd } from '@mui/icons-material'
import { Button } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
// import StudentProvider from './StudentProvider'
// import StudentTable from './components/StudentGrid'
import StudentFilter from './components/StudentFilter'
import { useAddStudent } from './hooks/useAddStudent'
import StudentTableProvider from './StudentTableProvider'
import StudentTable from './components/StudentTable'
import { useCorePageContext } from '@Core/Provider/CorePageProvider'
import ExamStudentTable from '../ClassRoomDetail/ExamTab/ExamStudentTable'
import ExerciseStudentTable from '../ClassRoomDetail/ExamHomeworkTab/ExerciseStudentTable'
// import PropTypes from 'prop-types'

const Student = ({ class_room_id, classRoomDetail }) => {
	const { t } = useTranslation()
	const { handleOpenAddStuddent, renderDialog } = useAddStudent()
	const { exam, isFeatureExam, isFeatureExercise, exercise } = useCorePageContext()
	// console.log('============= exam', exam)

	const renderTable = () => {
		if (isFeatureExercise) {
			return <ExerciseStudentTable />
		}
		if (isFeatureExam) {
			return <ExamStudentTable />
		}
		return <StudentTable />
	}

	const context = {
		t,
		exam,
		isFeatureExam,
		isFeatureExercise,
		classRoomDetail,
		class_room_id,
		exercise
	}

	return (
		<StudentTableProvider {...context}>
			<CorePageLayout
				toolbar={<StudentFilter />}
				headerAction={
					<Button
						variant="contained"
						gradient="primary"
						disableElevation
						startIcon={<PersonAdd />}
						onClick={handleOpenAddStuddent}
					>
						{t('Thêm thành viên')}
					</Button>
				}
			>
				{renderTable()}

				{renderDialog({ classRoomDetail })}
			</CorePageLayout>
		</StudentTableProvider>
	)
}

// Student.defaultProps = {}

// Student.propTypes = {}

export default React.memo(Student)
