import adminStudentService from '@App/Studio/services/admin/adminStudentService'
import useCoreTable from '@Core/Components/Table/hooks/useCoreTable'
import CorePageProvider from '@Core/Provider/CorePageProvider'
import { useRequest } from 'ahooks'
import React, { useEffect } from 'react'

// import PropTypes from 'prop-types'

const StudentTableProvider = ({ children, ...props }) => {
	const { class_room_id, exam, exercise, isFeatureExam, isFeatureExercise } = props
	const requestStudents = useRequest(adminStudentService.list, {
		manual: true,
		debounceWait: 300
	})

	const tableHandler = useCoreTable(requestStudents)

	useEffect(() => {
		const params = {
			class_room_id
		}
		if (isFeatureExam) {
			params.exam_id = exam?.id
		}
		if (isFeatureExercise) {
			params.exercise_id = exercise.id
		}
		tableHandler.handleFetchData(params)
	}, [])

	const context = {
		tableHandler,
		exam,
		...props
	}
	return <CorePageProvider {...context}>{children}</CorePageProvider>
}

// StudentTableProvider.defaultProps = {}

// StudentTableProvider.propTypes = {}

export default React.memo(StudentTableProvider)
