import { getQueryUrlObject } from '@Core/Helpers/Url'
import { useRequest } from 'ahooks'
import React, { useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { get } from 'lodash'
import { Alert, AlertTitle, Button } from '@mui/material'
import AppCard from '@Core/Components/Card/AppCard'
import quizExamService from '@App/Quiz/services/quizExamService'
import quizExamRoomService from '@App/Quiz/services/quizExamRoomService'
// import PropTypes from 'prop-types'

const QuizExamRoomContext = React.createContext({
	exam_room: {},
	examResult: {},
	setExamResult: () => {},
	getExamRoomConfig: key => {},
	isAlertExitExamScreen: false,
	setIsAlertExitExamScreen: () => {},
	renderAlertExitExamScreen: () => {},
	checkExitExamScreen: () => {},
	handleSaveLog: (type, data) => {},
	isExamRoomCompleted: false,
	setIsExamRoomCompleted: value => {},
	disableCheckAnti: false,
	setDisableCheckAnti: () => {},
	handleStartExamTest: () => {},
	handleBackRoom: () => {},
	roomView: 'waiting',
	handleFetchRoom: () => {},
	loading: false
})

export const useQuizExamRoomContext = () => React.useContext(QuizExamRoomContext)

const QuizExamRoomProvider = ({ children }) => {
	// const [query] = useSearchParams()
	// const { setError } = useCoreAppContext()
	const { state } = useLocation()
	const { alias } = useParams()
	const [examResult, setExamResult] = useState({})
	const [exitExamScreenCount, setExitExamScreenCount] = useState(0)
	const [isAlertExitExamScreen, setIsAlertExitExamScreen] = useState(false)
	const [isExamRoomCompleted, setIsExamRoomCompleted] = useState(false)
	const [disableCheckAnti, setDisableCheckAnti] = useState(false) // bật / tắt chống gian lận
	// console.log('============= examResult', examResult)
	const [roomView, setRoomView] = useState('waiting')
	const navigate = useNavigate()

	const { runAsync, data, loading, error } = useRequest(quizExamRoomService.findExamRoom, {
		manual: true,
		onError: () => {
			navigate('/404', { replace: true })
		}
	})
	const exam_room = state?.exam_room ? state.exam_room : { ...data, class_room_id: state?.class_room_id }
	// const isFullscreen = get(exam_room, 'config.is_fullscreen')
	// console.log('data room: ', data, state)

	const getExamRoomConfig = key => {
		return get(exam_room, `config.${key}`)
	}

	const checkExitExamScreen = () => {
		const anti_exit_exam_screen_number = getExamRoomConfig('anti_exit_exam_screen_number')
		return exitExamScreenCount > parseInt(anti_exit_exam_screen_number, 10)
	}

	const handleStartExamTest = () => {
		setRoomView('test')
	}

	const handleBackRoom = () => {
		setRoomView('waiting')
		// handleFetchRoom()
		setTimeout(() => {
			window.location.reload()
		}, 200)
	}

	const handleFetchRoom = () => {
		const query = getQueryUrlObject()
		if (alias) {
			runAsync(alias)
		} else if (query?.alias) runAsync(query?.alias)
	}

	const renderAlertExitExamScreen = () => {
		const anti_exit_exam_screen_number = getExamRoomConfig('anti_exit_exam_screen_number')
		return (
			<AppCard
				footer={
					<Button variant="contained" gradient="primary" onClick={() => setIsAlertExitExamScreen(false)}>
						Tiếp tục bài thi
					</Button>
				}
			>
				<Alert severity="error">
					<AlertTitle>Cảnh báo hành vi gian lận</AlertTitle>
					Bạn vừa thoát màn hình thi, nếu vượt quá <strong>{anti_exit_exam_screen_number}</strong> lượt thì hệ
					thống sẽ tự động nộp bài thi
				</Alert>
			</AppCard>
		)
	}

	const handleSaveLog = (type, data) => {
		if (exam_room?.id && examResult?.id) {
			quizExamService.saveLog({
				exam_room_id: exam_room?.id,
				exam_result_id: examResult?.id,
				candidate_id: examResult.user_id,
				type,
				custom_data: data
			})
		}
	}

	const context = {
		exam_room,
		examResult,
		setExamResult,
		getExamRoomConfig,
		setExitExamScreenCount,
		exitExamScreenCount,
		isAlertExitExamScreen,
		setIsAlertExitExamScreen,
		renderAlertExitExamScreen,
		checkExitExamScreen,
		handleSaveLog,
		setIsExamRoomCompleted,
		isExamRoomCompleted,
		disableCheckAnti,
		setDisableCheckAnti,
		handleStartExamTest,
		roomView,
		handleBackRoom,
		handleFetchRoom,
		loading
	}
	return <QuizExamRoomContext.Provider value={context}>{children}</QuizExamRoomContext.Provider>
}

// QuizExamRoomProvider.defaultProps = {}

// QuizExamRoomProvider.propTypes = {}

export default React.memo(QuizExamRoomProvider)
