import { LocalLibrary } from '@mui/icons-material'
import { Box, Tooltip, Typography } from '@mui/material'
import clsx from 'clsx'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { MdAnalytics } from 'react-icons/md'
// import PropTypes from 'prop-types'

const ExamIconResult = ({ className, exam }) => {
	const { t } = useTranslation()
	return (
		<Tooltip title={t('Số lượt thi')} placement="bottom">
			<Box
				sx={{
					// ml: 3,
					display: 'flex',
					alignItems: 'center',
					'& .MuiSvgIcon-root': {
						fontSize: 16
					}
				}}
				className={clsx('gap-2', className)}
			>
				<LocalLibrary color="success" />
				<Typography variant="M14">{exam?.results_count ?? 0}</Typography>
			</Box>
		</Tooltip>
	)
}

// ExamIconResult.defaultProps = {}

// ExamIconResult.propTypes = {}

export default React.memo(ExamIconResult)
