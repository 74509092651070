import { QUIZ_ROUTER_EXAM } from '@App/Quiz/Modules/Exam/configs/const'
import { Button } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
// import PropTypes from 'prop-types'

const ButtonViewResult = ({ result_id }) => {
	const { t } = useTranslation()
	return (
		<Button
			variant="contained"
			className="whitespace-nowrap"
			size="small"
			onClick={() => {
				window.open(`${window.location.origin}/quiz/${QUIZ_ROUTER_EXAM.admin_result(result_id)}`, '_blank')
			}}
		>
			{t('Xem chi tiết')}
		</Button>
	)
}

// ButtonViewResult.defaultProps = {}

// ButtonViewResult.propTypes = {}

export default React.memo(ButtonViewResult)
