import BaseService from '@Core/axios/BaseService'

class ExamStage extends BaseService {
	BASE_ENDPOINT = '/workspace/studio/api/v1/admin/exam-stages'

	constructor() {
		super()
		this.setRequest()
	}

	getExamResults = (query = {}, config = {}) => {
		const endpoint = '/quizexam/studio/api/v1/admin/exam-results'
		const params = { ...this.paramsGet, ...query }
		return this.request.get(endpoint, { params, ...config })
	}

	getDetailExamResult = id => {
		const endpoint = `/quizexam/studio/api/v1/admin/exam-results/${id}`

		return this.request.get(endpoint)
	}

	saveExamStudent = data => {
		const endpoint = '/workspace/studio/api/v1/admin/exam-stages/save-exam-student'
		return this.request.post(endpoint, data)
	}

	getExamStudentConfig = id => {
		const endpoint = `/workspace/studio/api/v1/admin/exam-stages/${id}/get-exam-student`
		return this.request.get(endpoint)
	}
}

const examStageService = new ExamStage()

export default examStageService
