import { CoreAutocomplete, CoreInputSearch } from '@Core/Components/Input'
import TotalData from '@Core/Components/PageLayout/components/TotalData'
import { useTableFilter } from '@Core/Components/Table/hooks/useTableFilter'
import { EXAM_ROOM } from '@Core/Components/const'
import { useCorePageContext } from '@Core/Provider/CorePageProvider'
import { Box } from '@mui/material'
import { memo, useMemo } from 'react'

const ExamHomeworkFilter = props => {
	const { tableHandler, totalData, loading, t } = useCorePageContext()
	const { methodFilter } = useTableFilter({
		handleFetchData: tableHandler.handleFetchData,
		querySearch: { search_exam: '' },
		queryFilters: { status: null }
	})
	const { control, watch } = methodFilter

	const sttOptions = useMemo(() => {
		return Object.keys(EXAM_ROOM.status).map(key => {
			return {
				label: t(key),
				value: key
			}
		})
	}, [t])

	return (
		<Box className="flex space-x-6">
			<CoreInputSearch control={control} name="search_exam" className="w-128" />
			<CoreAutocomplete
				control={control}
				name="status"
				size="small"
				options={sttOptions}
				className="w-128"
				placeholder="Trạng thái"
				returnValueType="enum"
			/>
			{/* <TotalData loading={loading} totalData={totalData} title={t('Bài tập')} /> */}
		</Box>
	)
}

export default memo(ExamHomeworkFilter)
