import { isFunction } from 'lodash'
import React from 'react'
import { useNavigate } from 'react-router-dom'
// import PropTypes from 'prop-types'

const LogoBusiness = ({ onClick }) => {
	const navigate = useNavigate()

	// const handleClick = () => {
	// 	if (isFunction(onClick)) {
	// 		onClick()
	// 	} else {
	// 		navigate(ROUTER_PERSONAL_DASHBOARD.dashboard)
	// 	}
	// }

	return (
		<div role="button" className="flex items-center gap-2 px-3 py-2 cursor-pointer">
			<div className="w-[230px]">
				<img src="/assets/images/logo_business.png" alt="EduQuiz.io" title="EduQuiz.io" />
			</div>
		</div>
	)
}

// LogoBusiness.defaultProps = {}

// LogoBusiness.propTypes = {}

export default React.memo(LogoBusiness)
