import { get, sample } from 'lodash'
import { convertUrlWithParams, getS3Url, isLocalhost, isStudioProduction } from './Url'
import urlcat from 'urlcat'
import { QUIZ_ROUTER_EXAM } from '@App/Quiz/Modules/Exam/configs/const'
import { EDUQUIZ_APP } from 'env'
import { getAuthTokenSession, getSystemSettingSession } from './Session'

const randomExam = [
	'/assets/images/default/exam-01.png',
	'/assets/images/default/exam-02.png',
	'/assets/images/default/exam-03.png',
	'/assets/images/default/exam-04.png'
]
const randomClass = [
	'/assets/images/default/Class-01.png',
	'/assets/images/default/Class-02.png',
	'/assets/images/default/Class-03.png',
	'/assets/images/default/Class-04.png'
]

const channel_bg_default = [
	'https://s3.eduquiz.io.vn/default/channel/channel_bg_1.png',
	'https://s3.eduquiz.io.vn/default/channel/channel_bg_2.png',
	'https://s3.eduquiz.io.vn/default/channel/channel_bg_3.png',
	'https://s3.eduquiz.io.vn/default/channel/channel_bg_4.png',
	'https://s3.eduquiz.io.vn/default/channel/channel_bg_tet.png'
]

export const getExamThumnail = exam => {
	const img_default = getSystemSettingSession('img_default.exam')
	// console.log('============= img_default', img_default)
	const src = exam?.thumbnail_url ? getS3Url(exam.thumbnail_url) : sample(img_default)
	// console.log('============= sample(random)[0]', sample(random))
	return src
}

export const getClassThumbnail = classRoom => {
	const src = classRoom?.avatar_url ? getS3Url(classRoom.avatar_url) : sample(randomClass)
	// console.log('============= sample(random)[0]', sample(random))
	return src
}

export const getCategoryThumbnail = category => {
	const img_default = getSystemSettingSession('img_default.category')
	const src = category?.avatar_url ? getS3Url(category.avatar_url) : sample(img_default)
	return src
}

export const getChannelBgDefault = () => {
	const img_default = getSystemSettingSession('img_default.channel_bg') ?? channel_bg_default
	const src = sample(img_default)
	// console.log('============= sample(random)[0]', sample(random))
	return src
}

export const getUrlSharing = (item, type, field = 'id', params = {}) => {
	// console.log('URL: ', item)
	if (type === 'room') {
		const value = get(item, field)
		// console.log('============= value', value)
		return `${window.location.origin}/quiz/room/${value}`
	}
	// if (type === 'class') {
	// 	const value = get(item, field)
	// 	return `${window.location.origin}/join/?${type}=${value}`
	// }
	if (type === 'exam') {
		const value = get(item, field)
		let url = `https://eduquiz.vn/de-thi/${value}`
		if (isLocalhost()) {
			url = urlcat(`${window.location.origin}/quiz/${QUIZ_ROUTER_EXAM.test}`, {
				alias: value
			})
		}
		return convertUrlWithParams(url, params)
	}
	const value = get(item, field)
	return `${window.location.origin}/join?${type}=${value}`
}

export const openExamTestUrl = item => {
	const token = getAuthTokenSession()
	const path = item && item?.version === 3 ? `/de-thi/${item.alias}` : `/quiz/${item?.id}/${item.alias}.html`
	let url = convertUrlWithParams(`${EDUQUIZ_APP}${path}`, token)
	// console.log('============= url', url)
	if (isLocalhost()) {
		url = `${window.location.origin}/quiz/exam/test/${item?.alias}`
	}
	// const url = `${window.location.origin}/quiz/exam/test/${item?.alias}`
	window.open(url, '_blank')
}

export const openSearchExam = text => {
	const url = `${window.location.origin}/quiz/exam/search?q=${text}`
	window.open(url, '_blank')
}

export const openChannelExam = channel => {
	let url = `kenh-de-thi/${channel?.alias}`
	const token = getAuthTokenSession()
	if (isStudioProduction()) {
		url = `https://eduquiz.vn/${url}`
		url = convertUrlWithParams(url, token)
	} else {
		url = `https://eduquiz-landing-page-dev.vercel.app/${url}`
		url = convertUrlWithParams(url, token)
	}

	if (isLocalhost()) {
		url = urlcat(`/quiz/${QUIZ_ROUTER_EXAM.channel}`, { alias: channel?.alias })
	}
	// const url = window.location.origin + urlcat(`/quiz/${QUIZ_ROUTER_EXAM.channel}`, { alias: channel?.alias })
	// navigate(url)
	window.open(url, '_blank')
}

export const isExamStudyTest = exam => {
	return exam?.mode === 'study'
}

export const isExamTest = exam => {
	return exam?.mode === 'test'
}
