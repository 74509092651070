import adminExamRoomService from '@App/Studio/Modules/Workspace/ExamRoom/services/admin/adminExamRoomService'
import ExamResultStatisRank from '@Core/Components/Common/ExamResult/Table/ExamResultStatisRank'
import StageRoomStatusLabel from '@Core/Components/Common/ExamRoom/StageRoomStatusLabel'
import { useConfirm } from '@Core/Components/Confirm/CoreConfirm'
import CoreTable, { columnHelper } from '@Core/Components/Table/CoreTable'
import { CoreActionDelete, CoreActionEdit, CoreActionIcon } from '@Core/Components/Table/components/CoreTableAction'
import { EXAM_ROOM } from '@Core/Components/const'
import { successMsg } from '@Core/Helpers/Message'
import { formatDate } from '@Core/Helpers/String'
import { useCorePageContext } from '@Core/Provider/CorePageProvider'
import { CancelPresentation, FactCheck, PlayCircle } from '@mui/icons-material'
import { Chip, Typography } from '@mui/material'
import { Gauge, gaugeClasses } from '@mui/x-charts'
import React, { useMemo } from 'react'
import { IoShareSocial } from 'react-icons/io5'
import { useNavigate } from 'react-router-dom'
import AvgTotalScore from '../../../components/Table/AvgTotalScore'
import ExerciseAction from '../../../components/Table/ExerciseAction'
// import PropTypes from 'prop-types'

const ExamExerciseTable = props => {
	const { tableHandler, t, handleDeleteExamHomework, classRoomDetail } = useCorePageContext()

	const navigate = useNavigate()

	const confirm = useConfirm()

	const handleConfirmPause = id => {
		confirm({
			title: 'Bạn có chắc chắn muốn tạm dừng bài kiểm tra này không?',
			color: 'error',
			onOk: async () => {
				await adminExamRoomService.changeStatus({ id, status: EXAM_ROOM.status.pause })
				successMsg(t('Tạm hoãn bài kiểm tra thành công'))
				tableHandler.handleFetchData()
			}
		})
	}

	const handleConfirmContinue = id => {
		confirm({
			title: 'Bạn có chắc chắn muốn tiếp tục giao bài kiểm tra này không?',
			color: 'error',
			onOk: async () => {
				await adminExamRoomService.changeStatus({ id, status: EXAM_ROOM.status.going_on })
				successMsg(t('Tiếp tục giao bài kiểm tra thành công'))
				tableHandler.handleFetchData()
			}
		})
	}

	const columns = useMemo(
		() => [
			columnHelper.accessor('exam.name', {
				header: t('Bài kiểm tra')
			}),
			columnHelper.accessor('start_date', {
				header: t('Thời gian bắt đầu'),
				cell: ({ getValue }) => (
					<p className="whitespace-nowrap">{formatDate(getValue(), 'HH:mm DD-MM-YYYY')}</p>
				)
			}),
			columnHelper.accessor('end_date', {
				header: t('Thời gian kết thúc'),
				cell: ({ getValue }) => (
					<p className="whitespace-nowrap">{formatDate(getValue(), 'HH:mm DD-MM-YYYY')}</p>
				)
			}),
			columnHelper.accessor('exam_config.exam_test_time', {
				header: t('Thời gian làm bài'),
				cell: ({ row, getValue }) => (
					<Typography variant="M14">{t('{{time}} phút', { time: getValue() })}</Typography>
				)
			}),
			columnHelper.accessor('status', {
				header: t('Trạng thái'),
				cell: ({ getValue }) => <StageRoomStatusLabel status={getValue()} />
			}),
			// columnHelper.accessor('total_question', {
			// 	// 	header: t('Tổng câu hỏi')
			// }),
			columnHelper.accessor('results_count', {
				header: t('Thành viên hoàn thành'),
				cell: ({ row, getValue }) => (
					<div className="w-full text-center">
						<Typography variant="M14">
							{getValue()} / {row.original.candidates_count}
						</Typography>
					</div>
				)
			}),
			columnHelper.accessor('avg_total_score', {
				header: t('Điểm trung bình'),
				cell: ({ getValue }) => <AvgTotalScore total_score={getValue()} />
			}),
			columnHelper.accessor('statis_rank', {
				header: t('Thống kê xếp loại kết quả'),
				cell: ({ row, getValue }) => <ExamResultStatisRank rank_data={getValue()} />
			}),

			columnHelper.accessor('id', {
				header: t('Hành động'),
				cell: ({ getValue, row }) => <ExerciseAction classRoomDetail={classRoomDetail} item={row.original} />
			})
		],
		[t]
	)

	return <CoreTable isShowPagination columns={columns} {...tableHandler} />
}

// ExamExerciseTable.defaultProps = {}

// ExamExerciseTable.propTypes = {}

export default React.memo(ExamExerciseTable)
