import { ROUTER_AUTH } from '@App/Auth/configs/const'
import { useQuizExamRoomContext } from '@App/Quiz/Modules/Exam/pages/ExamRoom/QuizExamRoomProvider'
import { ROUTER_MY_ACCOUNT } from '@App/Studio/Modules/MyAccount/configs/const'
import { ROUTER_PERSONAL_DASHBOARD } from '@App/Studio/Modules/Personal/Dashboard/configs/const'
import { ROUTER_WORKSPACE_EXAM } from '@App/Studio/Modules/Workspace/Exam/configs/const'
import DropDownAction from '@Core/Components/Dropdown/DropDownAction'
import { getS3Url } from '@Core/Helpers/Url'
import { useCoreAuth, useCoreAuthActions } from '@Core/Provider/CoreAuthProvider'
import { AccountBox, ExitToAppOutlined, LibraryBooks, Quiz } from '@mui/icons-material'
import { Avatar, Button, Skeleton, Tooltip, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
// import PropTypes from 'prop-types'

const UserHeader = () => {
	const { user, isLoading } = useCoreAuth()
	const { logout } = useCoreAuthActions()
	const { exam_room } = useQuizExamRoomContext()

	const { t } = useTranslation()
	const navigate = useNavigate()

	// console.log('🚀 ~ User ~ user:', user)

	const userActions = [
		{
			label: (
				<div className="flex items-center gap-2 min-w-96">
					<AccountBox />
					<Typography variant="M14">{t('Profile')}</Typography>
				</div>
			),
			action: () => {
				const url = `${window.location.origin}${ROUTER_MY_ACCOUNT.profile}`
				window.open(url, '_blank')
				// navigate(ROUTER_MY_ACCOUNT.profile)
			},
			divider: true,
			hidden: !!exam_room?.id
		},
		{
			label: (
				<div className="flex items-center gap-2 min-w-96">
					<LibraryBooks />
					<Typography variant="M14">{t('Thư viện của tôi')}</Typography>
				</div>
			),
			action: () => {
				const url = `${window.location.origin}${ROUTER_PERSONAL_DASHBOARD.dashboard}`
				window.open(url, '_blank')
				// navigate(ROUTER_MY_ACCOUNT.profile)
			},
			divider: true,
			hidden: !!exam_room?.id
		},
		{
			label: (
				<div className="flex items-center gap-2 min-w-96">
					<Quiz />
					<Typography variant="M14">{t('Quản lý đề thi')}</Typography>
				</div>
			),
			action: () => {
				const url = `${window.location.origin}${ROUTER_WORKSPACE_EXAM.list}`
				window.open(url, '_blank')
				// navigate(ROUTER_MY_ACCOUNT.profile)
			},
			hidden: !!exam_room?.id
			// divider: true
		},
		{
			label: (
				<div className="flex gap-2">
					<ExitToAppOutlined className="text-mainOrange" />
					<Typography variant="M14">{t('Log out')}</Typography>
				</div>
			),
			action: () => logout(),
			hidden: false
		}
	].filter(i => i?.hidden !== true)
	// console.log('============= userActions', userActions)
	return isLoading ? (
		<div className="flex items-center gap-2 px-2 bg-gray-100 rounded-md">
			<Skeleton variant="circular" className="w-[40px] h-[40px]" />
			<Skeleton variant="text" className="w-[120px] h-[32px]" />
		</div>
	) : (
		<div className="flex items-center gap-4">
			{!user?.id ? (
				<>
					<Typography>{t('You are not logged in')}</Typography>
					<Button onClick={() => navigate(ROUTER_AUTH.login)} variant="main-gradient">
						{t('Login')}
					</Button>{' '}
				</>
			) : (
				<DropDownAction
					options={userActions}
					triggerElement={
						<div className="flex items-center gap-2 p-2 rounded-md hover:bg-gray-100">
							<Tooltip title={t('Tài khoản của bạn')}>
								<Avatar
									src={getS3Url(user?.info?.avatar_url)}
									alt={user?.displayName}
									className="border"
								/>
							</Tooltip>
							{/* <Typography variant="M16" className="hidden sm:inline-flex">
								{user?.displayName}
							</Typography> */}
						</div>
					}
				/>
			)}
		</div>
	)
}

// UserHeader.defaultProps = {}

// UserHeader.propTypes = {}

export default React.memo(UserHeader)
