import CorePageProvider from '@Core/Provider/CorePageProvider'
import React from 'react'
// import PropTypes from 'prop-types'

const ClassRoomDetailProvider = ({ children, ...resProps }) => {
	const context = {
		...resProps
	}
	return <CorePageProvider {...context}>{children}</CorePageProvider>
}

// ClassRoomDetailProvider.defaultProps = {}

// ClassRoomDetailProvider.propTypes = {}

export default React.memo(ClassRoomDetailProvider)
