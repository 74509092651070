import { ROUTER_WORKSPACE_EXAM } from '@App/Studio/Modules/Workspace/Exam/configs/const'
import CoreTable, { columnHelper } from '@Core/Components/Table/CoreTable'
import { CoreActionIcon, CoreActionView } from '@Core/Components/Table/components/CoreTableAction'
import { getUrlSharing, openExamTestUrl } from '@Core/Helpers/Exam'
import { useCorePageContext } from '@Core/Provider/CorePageProvider'
import { Analytics, FactCheck, OpenInBrowser, OpenInNew, ViewList } from '@mui/icons-material'
import { Avatar, Chip, Typography } from '@mui/material'
import { blue, green, red } from '@mui/material/colors'
import { getValue } from '@mui/system'
import { Gauge, PieChart, gaugeClasses } from '@mui/x-charts'
import React, { useMemo } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import urlcat from 'urlcat'
import { ROUTER_WORKSPACE_CLASS_ROOM } from '../../../configs/const'
import ExamResultStatisRank from '@Core/Components/Common/ExamResult/Table/ExamResultStatisRank'
import AvgTotalScore from '../../../components/Table/AvgTotalScore'
import { renderTime } from '@Core/Helpers/Date'
import ExamAction from '../../../components/Table/ExamAction'
// import PropTypes from 'prop-types'

const ExamTable = props => {
	const { tableHandler, t, classRoomDetail, student, isFeatureStudent } = useCorePageContext()
	const [search, setSearch] = useSearchParams()

	const navigate = useNavigate()
	const columns = useMemo(
		() =>
			[
				columnHelper.accessor('name', {
					header: t('Đề thi'),
					minSize: 200
				}),
				columnHelper.accessor('total_question', {
					header: t('Tổng câu hỏi')
				}),
				columnHelper.accessor('total_student_join', {
					header: t('Thành viên tham gia'),
					cell: ({ row, getValue }) => (
						<Typography variant="M14">
							{getValue()} / {row.original.total_student}
						</Typography>
					)
				}),
				columnHelper.accessor('total_result_study_test', {
					header: t('Lượt ôn tập')
				}),
				columnHelper.accessor('total_time_used.study_test', {
					header: t('Tổng thời gian ôn tập'),
					cell: ({ getValue }) => (getValue() > 0 ? renderTime(getValue()) : 0)
				}),
				columnHelper.accessor('total_result_test', {
					header: t('Lượt thi thử')
				}),
				columnHelper.accessor('total_time_used.test', {
					header: t('Tổng thời gian thi thử'),
					cell: ({ getValue }) => (getValue() > 0 ? renderTime(getValue()) : 0)
				}),
				columnHelper.accessor('avg_total_score_test', {
					header: t('Điểm trung bình'),
					cell: ({ getValue }) => <AvgTotalScore total_score={getValue()} />
				}),
				columnHelper.accessor('statis_rank', {
					header: t('Thống kê xếp loại'),
					cell: ({ row, getValue }) => <ExamResultStatisRank rank_data={getValue()} />
				}),
				columnHelper.accessor('id', {
					header: t('Hành động'),
					cell: ({ getValue, row }) => <ExamAction classRoomDetail={classRoomDetail} exam={row.original} />
				})
			].filter(Boolean),
		[t, student]
	)

	return <CoreTable isShowPagination columns={columns} {...tableHandler} />
}

// ExamTable.defaultProps = {}

// ExamTable.propTypes = {}

export default React.memo(ExamTable)
