/* eslint-disable jsx-a11y/anchor-is-valid */
import { LoadingButton } from '@mui/lab'
import { memo, useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import CoreInputMultipleEmail from '@Core/Components/Input/CoreInputMultipleEmail'
import { CoreAutocomplete, CoreCheckbox, CoreInput } from '@Core/Components/Input'
import { Link } from '@mui/icons-material'
import { errorMsg, successMsg } from '@Core/Helpers/Message'
import AdminSelectClassRoom from '../Admin/AdminSelectClassRoom'
import { EXAM_ROOM } from '@Core/Components/const'
import adminExamRoomService from '@App/Studio/Modules/Workspace/ExamRoom/services/admin/adminExamRoomService'
import { useExamRoomShareStatusAccess } from '@App/Studio/Modules/Workspace/ExamRoom/pages/ExamRoom/ExamRoomList/hooks/useExamRoomShareStatusAccess'
import { yupResolver } from '@hookform/resolvers/yup'
import Yup from '@Core/Helpers/Yup'
import { map } from 'lodash'
import { Alert, CircularProgress, Divider, InputAdornment, Typography } from '@mui/material'
import CoreDateTimePicker from '@Core/Components/Input/CoreDateTimePicker'
import { useRequest } from 'ahooks'
import CoreFieldSet from '@Core/Components/CoreFieldSet'
import ButtonCopy from '@Core/Components/Button/ButtonCopy'
import { getUrlSharing } from '@Core/Helpers/Exam'
import { LoadingFullPage } from '@Core/Components/CoreLoading'
import moment from 'moment'

const ExamHomework = ({ exam, exam_id, open }) => {
	const { t } = useTranslation()
	const [dataExercise, setDataExercise] = useState()
	const [isLoading, setIsLoading] = useState(false)
	// console.log('============= data', data)

	const {
		data: dataExams,
		runAsync,
		loading
	} = useRequest(adminExamRoomService.getExamExercise, {
		manual: true
	})

	const {
		watch,
		reset,
		control,
		handleSubmit,
		formState: { isDirty, isSubmitting }
	} = useForm({
		mode: 'onTouched',
		defaultValues: {
			exam_id,
			status_access: exam?.status_access ?? 'CLASSROOM',
			has_password: exam?.has_password ?? true,
			start_date: exam?.start_date ?? '',
			end_date: exam?.end_date ?? '',
			exam_duration: exam?.exam_duration ?? 0,
			exam_config: {
				is_shuffle_question: exam?.exam_config?.is_shuffle_question ?? false,
				is_shuffle_answer: exam?.exam_config?.is_shuffle_answer ?? false
			},
			class_room_ids: exam?.class_room_ids ?? [],
			candidate_emails: exam?.candidate_emails ?? []
		},
		resolver: yupResolver(
			Yup.object({
				password: Yup.mixed().when(['status_access'], {
					is: val => val === EXAM_ROOM.status_access.link_password,
					then: Yup.string().trim().required().max(8).min(6)
				}),

				start_date: Yup.date().typeError(t('Trường này là bắt buộc')),
				end_date: Yup.date()
					.min(Yup.ref('start_date'), t('Thời gian phải nhiều hơn thời gian bắt đầu'))
					.typeError(t('Trường này là bắt buộc')),
				exam_config: Yup.object({
					exam_test_time: Yup.string().required(),
					sections: Yup.object(
						dataExams?.sections?.reduce((acc, cur, index) => {
							acc[`section_${cur?.id}`] = Yup.object({
								total_question: Yup.number()
									.min(0)
									.max(cur?.total_question, t(`Tối đa ${cur?.total_question} câu`))
									.required()
							})

							return acc
						}, {})
					)
				})
				// class_room_ids: Yup.mixed().when(['status_access'], {
				// 	is: val => val === EXAM_ROOM.status_access.classroom,
				// 	then: Yup.array().min(1).required()
				// })
			}),
			{},
			{ mode: 'async' }
		)
	})

	const { renderSelectStatus, statusAccess } = useExamRoomShareStatusAccess({ control, name: 'status_access' })

	useEffect(() => {
		reset()
		setDataExercise(null)
	}, [open])

	const handleCallSubmit = async data => {
		try {
			setIsLoading(true)

			const res = await adminExamRoomService.createExamHomework(data)

			setDataExercise(res)

			setIsLoading(false)
			successMsg(t('Lưu cấu hình thành công'))
		} catch (e) {
			errorMsg(e)
		}
	}

	const onSubmit = handleSubmit(async data => {
		// const startDate = `${data.start_date.getFullYear()}-${
		// 	data.start_date.getMonth() + 1
		// }-${data.start_date.getDate()} ${moment(data.start_date).format('HH:mm:ss')}`
		// const endDate = `${data.end_date.getFullYear()}-${
		// 	data.end_date.getMonth() + 1
		// }-${data.end_date.getDate()} ${moment(data.end_date).format('HH:mm:ss')}`

		const dataSubmit = {
			...data
			// start_date: startDate,
			// end_date: endDate
		}

		if (dataSubmit?.status_access === 'CLASSROOM') {
			if (dataSubmit?.candidate_emails?.length > 0 || dataSubmit?.class_room_ids?.length > 0) {
				handleCallSubmit(dataSubmit)
			} else {
				errorMsg(t('Vui lòng chọn lớp học hoặc email cần giao bài tập'))
			}
		} else {
			handleCallSubmit(dataSubmit)
		}
	})

	useEffect(() => {
		if (exam_id) runAsync(exam_id)
	}, [exam_id])

	const renderSectionConfig = () => {
		return dataExams?.sections
			.map((item, index) => {
				if (item.total_question < 1) return null
				return (
					<div key={item.id} className="flex justify-between mb-2">
						<div className="mb-1">
							<Typography className="pr-2 font-600">{item.name}</Typography>
							<Typography variant="M14">
								{t(`Tối đa {{total_question}} câu`, { total_question: item?.total_question })}
							</Typography>
						</div>
						<CoreInput
							control={control}
							name={`exam_config.sections.section_${item.id}.total_question`}
							size="small"
							type="number"
							className="w-92 min-w-92"
							defaultValue={item.total_question}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<Typography>{t('câu')}</Typography>
									</InputAdornment>
								)
							}}
						/>
					</div>
				)
			})
			.filter(Boolean)
	}

	return (
		<div>
			<FormProvider>
				<form className="flex flex-col gap-formItem" onSubmit={onSubmit}>
					{renderSelectStatus({ label: t('Phạm vi giao bài tập') })}
					{statusAccess === EXAM_ROOM.status_access.link_password && (
						<CoreInput
							control={control}
							name="password"
							size="small"
							label={t('Mật khẩu truy cập')}
							placeholder={t('Nhập mật khẩu truy cập')}
							required
						/>
					)}

					{statusAccess === EXAM_ROOM.status_access.classroom && (
						<>
							<AdminSelectClassRoom control={control} multiple />
							<CoreInputMultipleEmail
								control={control}
								name="candidate_emails"
								label={t('Thành viên khác')}
								size="small"
								placeholder={t('Nhập email')}
							/>
						</>
					)}
					<Divider>{t('Cấu hình bài tập')}</Divider>
					<div className="flex items-center gap-3">
						<CoreDateTimePicker
							className="w-full"
							control={control}
							name="start_date"
							label={t('Thời gian bắt đầu')}
							disablePast
							required
							size="small"
						/>
						<CoreDateTimePicker
							control={control}
							required
							name="end_date"
							label={t('Thời gian kết thúc')}
							size="small"
							className="w-full"
							disablePast
						/>
					</div>
					<div className="mt-2">
						<div className="flex flex-col gap-2 mb-2">
							{/* <Typography variant="M14" className="text-label">
							{t('Thời gian làm bài thi')}
						</Typography> */}
							<CoreAutocomplete
								control={control}
								name="exam_duration"
								label={t('Thời gian làm bài tập')}
								required
								// label={t('label.exam_test_time')}
								// placeholder={t('placeholder.exam_test_time')}
								size="small"
								disableClearable
								returnValueType="enum"
								className="w-full sm:w-1/2"
								options={[
									...[5, 10, 15, 30, 45, 60, 90, 120].map((item, index) => {
										return {
											value: item,
											label: `${item} phút`
										}
									})
								]}
							/>
						</div>
						<div className="mb-2 ">
							<Typography className="pr-2 mb-1 font-600">
								{t('Số câu hỏi lấy ra ngẫu nhiên từ đề thi')}
							</Typography>
							<CoreFieldSet title={t('Thiết lập giao bài tập')} className="p-2">
								{loading ? (
									<div className="flex items-center justify-center min-h-60">
										<CircularProgress />
									</div>
								) : (
									renderSectionConfig()
								)}
							</CoreFieldSet>
						</div>
					</div>
					<div className="flex items-center">
						<CoreCheckbox
							control={control}
							name="exam_config.is_shuffle_question"
							label={t('Đảo câu hỏi')}
							// disabled={checkDisableShuffle()}
						/>
						<CoreCheckbox
							control={control}
							name="exam_config.is_shuffle_answer"
							label={t('Đảo đáp án')}
							// disabled={checkDisableShuffle()}
						/>
					</div>
					{!loading && dataExercise?.id && (
						<div className="flex flex-col gap-2">
							<Typography variant="M14">{t('Chia sẻ liên kết truy cập bài tập')}</Typography>
							<Alert icon={<Link />} severity="primary" className="font-500">
								{getUrlSharing(dataExercise, 'exercise', 'alias')}
							</Alert>
							<div>
								<ButtonCopy url={getUrlSharing(dataExercise, 'exercise', 'alias')} />
							</div>
						</div>
					)}
					{isLoading && (
						<div className="flex flex-col items-center w-full min-h-60">
							<CircularProgress />
							{/* <div className="text-center">{t('Đang tạo đường dẫn')}</div> */}
						</div>
					)}
					<div className="text-right">
						<LoadingButton
							variant="contained"
							gradient="primary"
							type="submit"
							loading={isSubmitting}
							disabled={!isDirty}
						>
							{t('Xác nhận')}
						</LoadingButton>
					</div>
				</form>
			</FormProvider>
		</div>
	)
}

export default memo(ExamHomework)
