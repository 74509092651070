import BaseService from '@Core/axios/BaseService'

class ExamSetting extends BaseService {
	BASE_ENDPOINT = '/quizexam/studio/api/v1/admin/exam-config-ranks'

	constructor() {
		super()
		this.setRequest()
	}

	getConfigRank = params => {
		return this.request.get(this.BASE_ENDPOINT, { params })
	}

	// tạo & thêm cấu hình tùy chỉnh
	saveConfigRank = data => {
		const endpoint = '/quizexam/studio/api/v1/admin/exam-config-ranks/save'

		return this.request.post(endpoint, data)
	}

	// lưu cấu hình hiện tại cho đề thi
	updateConfigRankExam = data => {
		const endpoint = 'quizexam/studio/api/v1/admin/exam-config-ranks/update-exam-rank'

		return this.request.post(endpoint, data)
	}

	saveConfigShare = data => {
		const endpoint = '/quizexam/studio/api/v1/admin/exam-config/save'

		return this.request.post(endpoint, data)
	}

	getConfigShare = exam_id => {
		const endpoint = `/quizexam/studio/api/v1/admin/exam-config/${exam_id}`

		return this.request.get(endpoint)
	}

	// lấy danh sách bài tập của lớp học
	getExamHomeworkClassroom = params => {
		const endpoint = 'examroom/studio/api/v1/admin/exam-rooms'

		return this.request.get(endpoint, { params })
	}

	// check password bài tập
	checkPasswordExercise = data => {
		const endpoint = 'examroom/studio/api/v1/user/exam-rooms/check-password'

		return this.request.post(endpoint, data)
	}

	// sort questions
	sortQuestions = data => {
		const endpoint = 'quizexam/studio/api/v1/admin/questions/sort-order'

		return this.request.post(endpoint, data)
	}
}
const examSettingService = new ExamSetting()

export default examSettingService
