import ExamResultStatisQuestionLabel from '@Core/Components/Common/ExamResult/Table/ExamResultStatisQuestionLabel'
import ExamResultStatusLabel from '@Core/Components/Common/ExamResult/Table/ExamResultStatusLabel'
import CoreTable, { columnHelper } from '@Core/Components/Table/CoreTable'
import { renderTime } from '@Core/Helpers/Date'
import { useCorePageContext } from '@Core/Provider/CorePageProvider'
import { Avatar, Button } from '@mui/material'
import React, { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import AvgTotalScore from '../../../components/Table/AvgTotalScore'
import ExamResultStatisRank from '@Core/Components/Common/ExamResult/Table/ExamResultStatisRank'
import ButtonViewResult from '../../../components/Table/ButtonViewResult'
import RankLabel from '../../../components/Table/RankLabel'
import { formatDate } from '@Core/Helpers/String'
// import PropTypes from 'prop-types'

const ExerciseStudentTable = props => {
	const { tableHandler, t, classRoomDetail } = useCorePageContext()
	// const [search, setSearch] = useSearchParams()

	const navigate = useNavigate()
	// console.log('============= exam', exam)
	const columns = useMemo(
		() => [
			columnHelper.accessor('fullname', {
				header: t('Thành viên'),
				cell: ({ row, getValue }) => {
					return (
						<div className="flex items-center gap-2">
							<Avatar src={row.original?.user?.info?.avatar_url} className="border border-gray-200" />
							{getValue()}
						</div>
					)
				}
			}),
			columnHelper.accessor('email', {
				header: t('Email')
			}),
			columnHelper.accessor('result.status', {
				header: t('Trạng thái hoàn thành'),
				cell: ({ row, getValue }) => <ExamResultStatusLabel status={getValue() ?? 'TODO'} />
			}),
			columnHelper.accessor('end_date', {
				header: t('Thời gian nộp bài'),
				cell: ({ getValue }) => (
					<p className="whitespace-nowrap">{formatDate(getValue(), 'HH:mm DD-MM-YYYY')}</p>
				)
			}),
			columnHelper.accessor('result.total_time_used', {
				header: t('Thời gian hoàn thành'),
				cell: ({ row, getValue }) => (getValue() > 0 ? renderTime(getValue()) : null)
			}),
			columnHelper.accessor('result', {
				header: t('Thống kê câu hỏi'),
				cell: ({ getValue }) => (getValue() ? <ExamResultStatisQuestionLabel result={getValue()} /> : null)
			}),
			columnHelper.accessor('result.total_score', {
				header: t('Điểm số'),
				cell: ({ getValue }) => <AvgTotalScore total_score={getValue()} />
			}),
			columnHelper.accessor('result.rank_data', {
				header: t('Xếp loại'),
				cell: ({ row, getValue }) => getValue() && <RankLabel rank={getValue()} />
			}),
			columnHelper.accessor('result.id', {
				header: t('Hành động'),
				cell: ({ getValue }) => getValue() && <ButtonViewResult result_id={getValue()} />
			})
		],
		[t]
	)

	return <CoreTable isShowPagination columns={columns} {...tableHandler} />
}

// ExerciseStudentTable.defaultProps = {}

// ExerciseStudentTable.propTypes = {}

export default React.memo(ExerciseStudentTable)
