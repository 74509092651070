import Image from 'mui-image'
import React from 'react'
// import PropTypes from 'prop-types'

const PageMaintain = props => {
	return (
		<div>
			<div className="w-[90%] sm:w-[600px] xl:w-[580px]">
				<Image src="/assets/images/errors/403.svg" alt="403" className="" />
			</div>

			<div className="flex flex-col items-center gap-20">
				<p className="text-[22px] m-0 leading-[28px] font-500 text-[#131214]">Hệ thống bảo trì máy chủ</p>
				<p className="text-[18px] m-0 leading-[24px] font-500 text-center px-3">
					Hệ thống đang bảo trì đến 12:00 ngày 27/02/2025, các bạn vui lòng quay lại sau nhé!
				</p>
				<p className="text-[16px] m-0 leading-[20px] font-500  text-center">
					EduQuiz chân thành cảm ơn các bạn đã sử dụng ứng dụng!
				</p>
			</div>
		</div>
	)
}

// PageMaintain.defaultProps = {}

// PageMaintain.propTypes = {}

export default React.memo(PageMaintain)
