import userExamService from '@App/Studio/services/user/userExamService'
import { useRequest } from 'ahooks'
import React, { useEffect, useState } from 'react'

import CorePageProvider, { useCorePageContext } from '@Core/Provider/CorePageProvider'
import CorePageLayout from '@Core/Components/PageLayout/CorePageLayout'
import ExamGridLayout from '@Core/Components/Grid/ExamGridLayout'
import ExamFilter from './ExamFilter'
import adminExamService from '@App/Studio/Modules/Workspace/Exam/services/adminExamService'
import { CoreActionDelete, CoreActionEdit, CoreActionResult } from '@Core/Components/Table/components/CoreTableAction'
import { useNavigate } from 'react-router-dom'
import { ROUTER_WORKSPACE_EXAM } from '@App/Studio/Modules/Workspace/Exam/configs/const'
import ExamTable from './ExamTable'
import adminClassRoomService from '@App/Studio/services/admin/adminClassRoomService'
import useCoreTable from '@Core/Components/Table/hooks/useCoreTable'
import StudentExamTable from '../../Student/components/StudentExamTable'
import { Button } from '@mui/material'
import { useAddExam } from '../hooks/useAddExam'
// import PropTypes from 'prop-types'

const ExamTab = props => {
	const navigate = useNavigate()
	const { classRoomDetail, t, feature, student, isFeatureStudent } = useCorePageContext()
	const [firstLoading, setFirstLoading] = useState(false)

	const requestExams = useRequest(adminClassRoomService.getExams, {
		manual: true
	})

	const tableHandler = useCoreTable(requestExams)
	const { handleOpen, renderAddExamDialog } = useAddExam({ tableHandler, classRoomDetail })

	useEffect(() => {
		// const query = getQueryUrlObject()
		// console.log('============= classRoomDetail', classRoomDetail)
		const params = {
			classroom_id: classRoomDetail?.id,
			sort: 'updated_at'
		}
		if (isFeatureStudent) {
			params.user_id = student?.user_id
		}
		tableHandler.handleFetchData(params)
	}, [isFeatureStudent])

	const pageContext = { tableHandler, t, classRoomDetail, student, isFeatureStudent, ...props }

	return (
		<CorePageProvider {...pageContext}>
			<CorePageLayout
				toolbar={<ExamFilter />}
				headerAction={
					<Button variant="contained" gradient="primary" onClick={() => handleOpen(classRoomDetail)}>
						{t('Thêm đề ôn tập')}
					</Button>
				}
			>
				{isFeatureStudent ? <StudentExamTable /> : <ExamTable />}
			</CorePageLayout>
			{renderAddExamDialog()}
		</CorePageProvider>
	)
}

// ExamTab.defaultProps = {}

// ExamTab.propTypes = {}

export default React.memo(ExamTab)
