import { Box, Tooltip, Typography } from '@mui/material'
import clsx from 'clsx'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { BsQuestionSquareFill } from 'react-icons/bs'
// import PropTypes from 'prop-types'

const ExamTotalQuestion = ({ className, exam }) => {
	const { t } = useTranslation()

	return (
		<Tooltip title={t('Câu hỏi')} placement="bottom">
			<Box
				sx={{
					// ml: 3,
					display: 'flex',
					alignItems: 'center',
					'& .MuiSvgIcon-root': {
						fontSize: 16
					}
				}}
				className={clsx('gap-2', className)}
			>
				<BsQuestionSquareFill className="text-orange-500 MuiSvgIcon-root" />
				<Typography variant="M14">{exam?.total_question ?? 0}</Typography>
			</Box>
		</Tooltip>
	)
}

// ExamTotalQuestion.defaultProps = {}

// ExamTotalQuestion.propTypes = {}

export default React.memo(ExamTotalQuestion)
