// import { getAuthDataSession } from '@App/core/Helpers/Session'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

const useCoreTable = (requestFetchData, options = { isQueryUrl: true, tableId: '', page: 1, size: 10 }) => {
	const { t } = useTranslation('common')
	const baseConfigs = useMemo(
		() => ({
			isQueryUrl: true,
			deleteConfirmMsg: t('table.delete_confirm'),
			onConfirmDelete: async () => {},
			...options
		}),
		[options]
	)

	const [params, setParams] = useState({
		size: options.size
	})

	const { data, loading, run } = requestFetchData
	// const [queryUrl, setQueryUrl] = useUrlState()
	const handleFetchData = useCallback(
		query => {
			const filter = {
				// user_id: getAuthDataSession('id'),
				...params,
				...query
			}
			setParams(filter)
			return run(filter)
		},
		[params]
	)

	return {
		...data,
		pageIndex: data?.current_page ? data?.current_page : 1,
		pageSize: data?.per_page ?? 10,
		loading,
		handleFetchData,
		params
	}
}

export default useCoreTable
