/* eslint-disable jsx-a11y/interactive-supports-focus */
import { QUIZ_ROUTER_EXAM } from '@App/Quiz/Modules/Exam/configs/const'
import { ROUTER_WORKSPACE_EXAM } from '@App/Studio/Modules/Workspace/Exam/configs/const'
import { useStatusShareOption } from '@Core/Components/Common/ConfigShare/useStatusShareOption'
import ExamIconResult from '@Core/Components/Common/Exam/ExamIconResult'
import ExamIconViewed from '@Core/Components/Common/Exam/ExamIconViewed'
import ExamTotalQuestion from '@Core/Components/Common/Exam/ExamTotalQuestion'
import { useExamExerciseContext } from '@Core/Components/Common/ExamExercise/ExamExerciseProvider'
import CoreAvatar from '@Core/Components/CoreAvatar'
import { getExamThumnail, isExamStudyTest, isExamTest, openChannelExam, openExamTestUrl } from '@Core/Helpers/Exam'
import { formatDate } from '@Core/Helpers/String'
import { getS3Url } from '@Core/Helpers/Url'
import { isChannelBusiness } from '@Core/Helpers/User'
import { useBreakpoints } from '@Core/hooks'
import { PlayCircle, Schedule } from '@mui/icons-material'
import { Button, Card, CardActions, CardContent, CardMedia, Divider, Tooltip, Typography } from '@mui/material'
import { isFunction, upperFirst } from 'lodash'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { createSearchParams, useNavigate } from 'react-router-dom'
import urlcat from 'urlcat'
// import PropTypes from 'prop-types'

const ExamGridItem = ({
	item,
	isAdmin,
	showButtonStart = true,
	showFavouriteButton = false,
	showStatus = false,
	renderItemAction = () => {},
	maxWidth = 320,
	showCreator = false,
	showAction = false,
	showButtonAssign = false,
	variant
	// onClickItem = () => {}
}) => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const { getOptionByValue } = useStatusShareOption({ iconColor: 'primary' })

	const { renderButtonAssignExercise } = useExamExerciseContext()

	const img = useMemo(() => {
		return getExamThumnail(item)
	}, [item.id])

	// console.log('============= item', item)
	const handleClickCreator = () => {
		if (!isAdmin) {
			openChannelExam(item?.channel)
		}
	}

	const handleClickItem = () => {
		if (isExamTest(item) && isAdmin) {
			// onClickItem()
			navigate({
				pathname: ROUTER_WORKSPACE_EXAM.edit(item.id),
				search: createSearchParams({ step: 'question' }).toString()
			})
		} else {
			openExamTestUrl(item)
		}
	}

	return (
		<div
			className="shrink-0 transition-all duration-300 hover:shadow-lg hover:-translate-y-[0.2rem] cursor-pointer"
			style={{ flexBasis: '30%', maxWidth }}
		>
			<Card
				// variant="outlined"
				variant={variant}
				className="relative"
			>
				{showStatus && (
					<div className="absolute p-1 bg-white rounded-full top-2 left-2">
						<Tooltip title={getOptionByValue(item.status)?.desc}>
							{getOptionByValue(item.status)?.icon}
						</Tooltip>
					</div>
				)}
				<CardMedia
					onClick={handleClickItem}
					sx={{ height: { xs: 120, sm: 180 } }}
					image={img}
					title={item?.name}
				/>
				<CardContent className="px-3" onClick={handleClickItem}>
					<div className="flex flex-col gap-3">
						<Tooltip title={item?.name} placement="top-start">
							<Typography
								onClick={() => openExamTestUrl(item)}
								variant="M14"
								className="lineClamp"
								component="h4"
							>
								{upperFirst(item?.name)}
							</Typography>
						</Tooltip>
						<div>
							<div className="flex items-center gap-2 sm:ml-auto">
								<Schedule sx={{ fontSize: 20 }} />
								<Typography variant="M14">{formatDate(item.created_at)}</Typography>
							</div>
						</div>
						<div className="flex items-center gap-3">
							<ExamTotalQuestion exam={item} />
							<ExamIconViewed exam={item} />
							{isAdmin && <ExamIconResult exam={item} />}
						</div>
						{showCreator && (
							<div
								role="button"
								className="items-center justify-between hidden w-full overflow-hidden md:flex"
								onClick={handleClickCreator}
							>
								<Tooltip title="Xem kênh đề thi" placement="top">
									<div className="flex items-center gap-2 ">
										<CoreAvatar
											sx={{ width: 26, height: 26, border: 1, fontSize: 18 }}
											className="border-gray-300"
											avatar_url={getS3Url(
												isAdmin ? item?.creator?.info?.avatar_url : item?.channel?.avatar
											)}
											name={isAdmin ? item?.creator?.info?.fullname : item?.channel?.name}
										/>
										<Typography variant="M14" className="w-max lineClamp">
											{isAdmin ? item.creator?.info?.fullname : item?.channel?.name}
										</Typography>
									</div>
								</Tooltip>
							</div>
						)}
						{isChannelBusiness() && isAdmin && (
							<div className="flex items-center gap-1">
								<Typography variant="M14">{t('Chế độ')}:</Typography>
								<Typography variant="M14">{t(`mode_${item.mode}`)}</Typography>
							</div>
						)}
					</div>
				</CardContent>
				<Divider className="mt-auto" />
				{showAction && <CardActions>{renderItemAction(item)}</CardActions>}

				<CardActions>
					{showButtonStart && (
						<Button
							size="small"
							gradient="primary"
							variant="contained"
							startIcon={<PlayCircle />}
							disableElevation
							onClick={() => openExamTestUrl(item)}
						>
							{t('Vào ôn thi')}
						</Button>
					)}
					{showButtonAssign && renderButtonAssignExercise({ exam: item, exam_id: item.id })}
				</CardActions>
			</Card>
		</div>
	)
}

// ExamGridItem.defaultProps = {}

// ExamGridItem.propTypes = {}

export default React.memo(ExamGridItem)
