import { CoreActionDelete, CoreActionEdit, CoreActionIcon } from '@Core/Components/Table/components/CoreTableAction'
import { EXAM_ROOM } from '@Core/Components/const'
import { successMsg } from '@Core/Helpers/Message'
import { Analytics, CancelPresentation, FactCheck, PlayCircle } from '@mui/icons-material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { IoShareSocial } from 'react-icons/io5'
import urlcat from 'urlcat'
import { ROUTER_WORKSPACE_CLASS_ROOM } from '../../configs/const'
import { useNavigate, useSearchParams } from 'react-router-dom'
// import PropTypes from 'prop-types'

const ExerciseAction = ({
	classRoomDetail,
	item,
	handleConfirmPause,
	handleDeleteExamHomework,
	handleConfirmContinue
}) => {
	const { t } = useTranslation()
	const navigate = useNavigate()

	// const [search, setSearch] = useSearchParams()

	return (
		<div className="flex gap-2">
			{item?.status !== EXAM_ROOM.status.finished && (
				<CoreActionIcon
					color="warning"
					icon={<IoShareSocial />}
					titleTooltip={t('Sao chép link bài kiểm tra')}
					onClick={() => {
						const url = `${window.location.origin}/join?exercise=${item?.alias}`
						navigator.clipboard.writeText(url)
						successMsg(t('Đã sao chép liên kết'))
					}}
				/>
			)}
			{item?.status !== EXAM_ROOM.status.pause && (
				<>
					<CoreActionIcon
						icon={<Analytics color="success" />}
						titleTooltip={t('Xem thành tích')}
						onClick={() => {
							const url = urlcat(ROUTER_WORKSPACE_CLASS_ROOM.feature_detail, {
								id: classRoomDetail?.id,
								feature: 'exercise',
								feature_id: item?.id,
								tab: 'student'
							})
							navigate(url, {
								state: {
									exercise: item,
									classroom: classRoomDetail
								}
							})
						}}
					/>
				</>
			)}
			{/* {item?.status === EXAM_ROOM.status.going_on && (
				<>
					<CoreActionIcon
						titleTooltip={t('Tạm hoãn')}
						icon={<CancelPresentation color="error" />}
						onClick={() => handleConfirmPause(item?.id)}
					/>
				</>
			)} */}
			{item?.status === EXAM_ROOM.status.upcoming && (
				<>
					<CoreActionEdit onClick={() => {}} />
					<CoreActionDelete
						onConfirmDelete={() => {
							handleDeleteExamHomework(item?.id)
						}}
					/>
				</>
			)}
			{item?.status === EXAM_ROOM.status.pause && (
				<>
					<CoreActionIcon
						titleTooltip={t('Tiếp tục giao bài kiểm tra')}
						icon={<PlayCircle />}
						onClick={() => handleConfirmContinue(item.id)}
					/>
				</>
			)}
		</div>
	)
}

// ExerciseAction.defaultProps = {}

// ExerciseAction.propTypes = {}

export default React.memo(ExerciseAction)
