import { CoreActionIcon } from '@Core/Components/Table/components/CoreTableAction'
import { openExamTestUrl } from '@Core/Helpers/Exam'
import { Analytics, FactCheck, OpenInNew } from '@mui/icons-material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import urlcat from 'urlcat'
import { ROUTER_WORKSPACE_CLASS_ROOM } from '../../configs/const'
// import PropTypes from 'prop-types'

const ExamAction = ({ exam, isFeatureStudent, classRoomDetail }) => {
	const { t } = useTranslation()
	const [search, setSearch] = useSearchParams()
	const navigate = useNavigate()
	return (
		<div className="flex gap-2">
			<CoreActionIcon
				icon={<OpenInNew />}
				titleTooltip={t('Mở đề thi')}
				onClick={() => {
					openExamTestUrl(exam)
				}}
			/>
			{isFeatureStudent && (
				<CoreActionIcon
					icon={<FactCheck color="primary" />}
					titleTooltip={t('Xem kết quả')}
					onClick={() => {
						setSearch({ view: 'results', exam_id: exam.id })
					}}
				/>
			)}
			{!isFeatureStudent && (
				<CoreActionIcon
					icon={<Analytics color="success" />}
					titleTooltip={t('Xem thành tích')}
					onClick={() => {
						const url = urlcat(ROUTER_WORKSPACE_CLASS_ROOM.feature_detail, {
							id: classRoomDetail?.id,
							feature: 'exam',
							feature_id: exam?.id,
							tab: 'student'
						})
						navigate(url, {
							state: {
								exam,
								classroom: classRoomDetail
							}
						})
					}}
				/>
			)}
		</div>
	)
}

// ExamAction.defaultProps = {}

// ExamAction.propTypes = {}

export default React.memo(ExamAction)
