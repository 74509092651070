import React from 'react'
import { businessRouterConfig } from './appConfig'
import CoreRouterProvider from '@Core/Provider/CoreRouterProvider'
import MUIThemeQuizProvider from '@App/Quiz/ThemQuizProvider'
import Layout from './Layout'
// import PropTypes from 'prop-types'

const Business = props => {
	return (
		<CoreRouterProvider appRouter={businessRouterConfig} basePath="/business">
			<MUIThemeQuizProvider>
				<Layout />
			</MUIThemeQuizProvider>
		</CoreRouterProvider>
	)
}

// Business.defaultProps = {}

// Business.propTypes = {}

export default React.memo(Business)
