import { Button } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
// import PropTypes from 'prop-types'
const colorMap = {
	upcoming: 'primary',
	going_on: 'success',
	pause: 'warning',
	finished: 'error'
}
const StageRoomStatusLabel = ({ status }) => {
	const { t } = useTranslation()
	return (
		<Button
			size="small"
			color={colorMap[status]}
			variant="contained"
			disableElevation
			className="px-2 py-1 cursor-text whitespace-nowrap"
			disableRipple
			// variant="outlined"
			// style={{ backgroundColor: bgColor, color: textColor }}
		>
			{t(status)}
		</Button>
	)
}

// StageRoomStatusLabel.defaultProps = {}

// StageRoomStatusLabel.propTypes = {}

export default React.memo(StageRoomStatusLabel)
