import { useCorePageContext } from '@Core/Provider/CorePageProvider'
import { Typography } from '@mui/material'
import React from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import GridItemSkeleton from '../GridItemSkeleton'
import ExamGridItem from '../ExamGridItem'
import { useTranslation } from 'react-i18next'
import ExamGridItemExercise from '../ExamGridItemExercise'
// import PropTypes from 'prop-types'

const ExamGridLayout = ({ isAdmin = false, renderItemAction = item => {} }) => {
	const { t } = useTranslation()
	const { data, loading, handleFetchData, firstLoading } = useCorePageContext()

	const renderData = () => {
		if (firstLoading) {
			return Array(12)
				.fill()
				.map((item, index) => {
					return <GridItemSkeleton key={index} />
				})
		}
		return data?.data?.map((item, index) => {
			if (item.type === 'exercise') {
				return <ExamGridItemExercise isAdmin key={index} item={item} renderItemAction={renderItemAction} />
			}

			return <ExamGridItem key={index} item={item} isAdmin={isAdmin} renderItemAction={renderItemAction} />
		})
	}
	return (
		<div id="scrollableDiv" className="w-full md:max-h-[72vh] overflow-y-auto px-2 md:px-12">
			<InfiniteScroll
				dataLength={data?.data?.length ?? 12}
				next={() => {
					handleFetchData()
				}}
				hasMore={data?.data?.length < data?.total}
				// loader={skeletonUserActivity()}
				scrollableTarget="scrollableDiv"
			>
				<div className="grid w-full h-full grid-cols-2 gap-4 px-4 pb-4 sm:grid-cols-3 md:grid-cols-4 md:gap-formItem xl:grid-cols-5">
					{renderData()}

					{loading &&
						Array(12)
							.fill()
							.map((item, index) => {
								return <GridItemSkeleton key={index} />
							})}
				</div>
			</InfiniteScroll>
			{!loading && data?.data?.length === 0 && (
				<Typography component="p" variant="M14" className="w-full text-center text-label">
					{t('Không tìm thấy đề thi nào!')}
				</Typography>
			)}
		</div>
	)
}

// ExamGridLayout.defaultProps = {}

// ExamGridLayout.propTypes = {}

export default React.memo(ExamGridLayout)
