import React from 'react'
// import PropTypes from 'prop-types'
import Tab from '@mui/material/Tab'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import Box from '@mui/material/Box'
import { useCorePageTabContext } from '.'
import { Badge } from '@mui/material'
import clsx from 'clsx'

const TabHeader = props => {
	const { tabs, tabActive, setTabActive, tabAction, handleChangeTab } = useCorePageTabContext()
	// const navigate = useNavigate()
	const renderTabs = () => {
		return tabs.map((item, index) => {
			return (
				<Tab
					key={index}
					label={
						<Badge
							badgeContent={item?.badge}
							color="error"
							sx={{
								'& .MuiBadge-badge': {
									right: -18,
									top: '50%'
								}
							}}
						>
							{item?.label}
						</Badge>
					}
					disabled={item?.disabled}
					value={item?.key}
					className={clsx('normal-case min-h-[60px]', {
						'pr-8': item?.badge > 0
					})}
					icon={item?.icon}
					iconPosition="start"
				/>
			)
		})
	}

	return (
		<div className="mb-4">
			<TabContext value={tabActive}>
				<Box sx={{ borderBottom: 1, borderColor: 'divider' }} className="flex w-full bg-white shadow">
					<TabList onChange={(e, val) => handleChangeTab(val)} aria-label="lab API tabs example">
						{renderTabs()}
					</TabList>
					{tabAction && <div className="flex self-center gap-3 ml-auto mr-2">{tabAction}</div>}
				</Box>
			</TabContext>
		</div>
	)
}

// TabHeader.defaultProps = {}

// TabHeader.propTypes = {}

export default React.memo(TabHeader)
