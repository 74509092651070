import adminQuestionService from '@App/Studio/services/admin/adminQuestionService'
import { successMsg } from '@Core/Helpers/Message'
import { Box, Card, CardContent, CardHeader, LinearProgress, Typography } from '@mui/material'
import { chunk, first } from 'lodash'
import React, { useState } from 'react'
import SaveQuestionProgress from './SaveQuestionProgress'
// import PropTypes from 'prop-types'

const SaveQuestionDraft = ({ sections = {}, exam }) => {
	const exam_name = first(Object.values(sections))?.exam_name

	const renderSectionProgress = () => {
		const el = Object.values(sections).map(section => {
			return <SaveQuestionProgress key={section.id} section={section} />
		})
		return el
	}

	return (
		<Card sx={{ width: 300 }}>
			<CardHeader
				title={<Typography variant="M16">Đang lưu câu hỏi</Typography>}
				subheader={
					<div>
						<Typography variant="M14">Đề thi: {exam_name}</Typography>
					</div>
				}
			/>
			<CardContent sx={{ pt: 0 }}>{renderSectionProgress()}</CardContent>
		</Card>
	)
}

// SaveQuestionDraft.defaultProps = {}

// SaveQuestionDraft.propTypes = {}

export default React.memo(SaveQuestionDraft)
