import adminQuestionService from '@App/Studio/services/admin/adminQuestionService'
import { Box, LinearProgress, Typography } from '@mui/material'
import { useUpdateEffect } from 'ahooks'
import { chunk } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useCoreExamQuestionContext } from '.'
// import PropTypes from 'prop-types'

const SaveQuestionProgress = ({ section }) => {
	const { addSectionDone } = useCoreExamQuestionContext()
	const [progress, setProgress] = useState(0)
	const [buffer, setBuffer] = useState(10)
	const [successQuestions, setSuccessQuestions] = useState({})
	const questions = Object.values(section?.questions_draft)
	const totalQsSuccess = Object.keys(successQuestions).length

	// console.log('============= successQuestions', successQuestions)
	const chunkQuestions = chunk(questions, 5)

	// console.log('============= questions', chunkQuestions)

	const saveQuestions = (chunkIndex = 0) => {
		if (chunkIndex < chunkQuestions.length) {
			const items = chunkQuestions[chunkIndex]
			const requests = items.map(data => {
				return adminQuestionService
					.save({ ...data, section_id: section?.id, section_uuid: section?.uuid })
					.then(res => {
						// handleSaveSuccess(data.question_index, res)
						setSuccessQuestions(prev => {
							return {
								...prev,
								[res?.id]: true
							}
						})
						data?.onSaveSuccess()
					})
			})
			// console.log('============= requests', requests)
			Promise.all(requests).then(responses => {
				// console.log('============= responses', responses)

				saveQuestions(chunkIndex + 1)
			})
			// console.log('============= res', res)
		} else {
			addSectionDone(section?.id)
			// successMsg('Lưu toàn bộ câu hỏi thành công')
			// setTimeout(() => {
			// 	handleClose()

			// 	// setHasQuestionDraft(false)
			// }, 2000)
		}
	}

	useUpdateEffect(() => {
		setProgress(prev => {
			const newProgress = (totalQsSuccess / questions.length) * 100
			// const newProgress = prev + percent
			// console.log('============= newProgress', newProgress)
			const newBuffer = 10 + newProgress
			setBuffer(newBuffer > 100 ? 100 : newBuffer)
			return newProgress
		})
	}, [JSON.stringify(successQuestions)])

	useEffect(() => {
		saveQuestions()
	}, [])

	return (
		<div className="mb-3">
			<div className="flex justify-between gap-2">
				<Typography>{section?.name}</Typography>
				<Typography>
					{totalQsSuccess}/{questions.length}
				</Typography>
			</div>
			<Box sx={{ width: '100%', mt: 1 }}>
				<LinearProgress variant="buffer" value={progress} valueBuffer={buffer} />
			</Box>
		</div>
	)
}

// SaveQuestionProgress.defaultProps = {}

// SaveQuestionProgress.propTypes = {}

export default React.memo(SaveQuestionProgress)
