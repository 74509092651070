import { Chip, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
// import PropTypes from 'prop-types'

const ExamResultStatisQuestionLabel = ({ result }) => {
	const { t } = useTranslation()
	return (
		<div className="flex gap-2">
			<div className="flex flex-col items-center gap-2">
				<Chip label={t('Số câu')} size="small" color="primary" />
				<Typography variant="SB14">{result?.total_question}</Typography>
			</div>
			<div className="flex flex-col items-center gap-2">
				<Chip label={t('Đúng')} size="small" color="success" />
				<Typography variant="SB14">{result?.total_question_true}</Typography>
			</div>
			<div className="flex flex-col items-center gap-2">
				<Chip label={t('Sai')} size="small" color="error" />
				<Typography variant="SB14">{result?.total_question_false}</Typography>
			</div>
			<div className="flex flex-col items-center gap-2">
				<Chip label={t('Trống')} size="small" color="warning" />
				<Typography variant="SB14">{result?.total_question_blank}</Typography>
			</div>
		</div>
	)
}

// ExamResultStatisQuestionLabel.defaultProps = {}

// ExamResultStatisQuestionLabel.propTypes = {}

export default React.memo(ExamResultStatisQuestionLabel)
