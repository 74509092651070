import adminStudentService from '@App/Studio/services/admin/adminStudentService'
import CoreTable, { columnHelper } from '@Core/Components/Table/CoreTable'
import { errorMsg, successMsg } from '@Core/Helpers/Message'
import { useCorePageContext } from '@Core/Provider/CorePageProvider'
import { Avatar, Tooltip } from '@mui/material'
import React, { useCallback, useMemo } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import ExamResultStatisRank from '@Core/Components/Common/ExamResult/Table/ExamResultStatisRank'
import AvgTotalScore from '../../../components/Table/AvgTotalScore'
import StudentAction from '../../../components/Table/StudentAction'
import { isUserStudent } from '@Core/Helpers/User'
// import PropTypes from 'prop-types'

const StudentTable = props => {
	const { tableHandler, t, classRoomDetail, exam, isFeatureExam } = useCorePageContext()
	const [search, setSearch] = useSearchParams()
	const handleClickDelete = useCallback(
		row => {
			// showLoadingPage()
			return adminStudentService
				.delete(row.id)
				.then(result => {
					successMsg(t('Xoá thành công'))
					tableHandler.handleFetchData({ page: 1 })
				})
				.catch(err => {
					errorMsg(t('Xoá thất bại'))
				})
		},
		[t]
	)

	const navigate = useNavigate()
	// console.log('============= exam', exam)
	const columns = useMemo(
		() => [
			columnHelper.accessor('fullname', {
				header: t('Thành viên'),
				cell: ({ row, getValue }) => {
					return (
						<div className="flex items-center gap-2">
							<Avatar src={row.original?.user?.info?.avatar_url} className="border border-gray-200" />
							{getValue()}
						</div>
					)
				}
			}),
			columnHelper.accessor('email', {
				header: t('Email')
			}),
			columnHelper.accessor('results_test.total_exam_study', {
				header: t('Đề thi đã ôn')
			}),

			columnHelper.accessor('results_test.total_result', {
				header: t('Lượt ôn tập')
			}),
			!isUserStudent() &&
				columnHelper.accessor('results_exercise.total_exercise_result', {
					header: t('Bài kiểm tra'),
					cell: ({ getValue, row }) => {
						return (
							<Tooltip title={t('Đã hoàn thành: {{number}}', { number: getValue() })}>
								<div>
									{getValue()} / {row.original.total_exercise}
								</div>
							</Tooltip>
						)
					}
				}),

			!isUserStudent() &&
				columnHelper.accessor('results_exercise.avg_total_score', {
					header: t('Điểm kiểm tra trung bình'),
					cell: ({ getValue }) => <AvgTotalScore total_score={getValue()} />
				}),
			!isUserStudent() &&
				columnHelper.accessor('results_exercise.statis_rank', {
					header: t('Thống kê xếp loại kiểm tra'),
					cell: ({ row, getValue }) => {
						const rank_data = getValue()
						return <ExamResultStatisRank rank_data={rank_data} />
					}
				}),
			columnHelper.accessor('id', {
				header: t('Hành động'),
				cell: ({ getValue, row }) => (
					<StudentAction
						classRoomDetail={classRoomDetail}
						isFeatureStudent
						item={row.original}
						handleClickDelete={handleClickDelete}
					/>
				)
			})
		],
		[t]
	)

	return <CoreTable isShowPagination columns={columns} {...tableHandler} />
}

// StudentTable.defaultProps = {}

// StudentTable.propTypes = {}

export default React.memo(StudentTable)
