/* eslint-disable jsx-a11y/anchor-is-valid */
import adminClassRoomService from '@App/Studio/services/admin/adminClassRoomService'
import ButtonCopy from '@Core/Components/Button/ButtonCopy'
import CoreInputMultipleEmail from '@Core/Components/Input/CoreInputMultipleEmail'
import { getUrlSharing } from '@Core/Helpers/Exam'
import { errorMsg, successMsg } from '@Core/Helpers/Message'
import { useCorePageContext } from '@Core/Provider/CorePageProvider'
import { Link } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Alert, Box, Divider, Typography } from '@mui/material'
import React from 'react'
import { useForm } from 'react-hook-form'
// import PropTypes from 'prop-types'

const StudentForm = ({ handleClose, classRoom }) => {
	const { t, handleFetchData, classRoomDetail } = useCorePageContext()
	const {
		control,
		handleSubmit,
		formState: { isDirty, isSubmitting },
		reset
	} = useForm({
		mode: 'onTouched',
		defaultValues: {
			student_emails: [],
			class_room_id: classRoom?.id
		}
	})

	const onSubmit = handleSubmit(async data => {
		try {
			const res = await adminClassRoomService.addStudents(data)
			handleFetchData({ page: 1 })
			handleClose()
			successMsg('Thêm thành viên thành công')
			reset()
		} catch (e) {
			errorMsg(e)
		}
	})
	return (
		<div>
			<div className="flex flex-col gap-2">
				<Typography variant="M14">{t('Chia sẻ liên kết này với thành viên của bạn để vào lớp học')}</Typography>
				<Alert icon={<Link fontSize="inherit" />} severity="primary" className="font-500">
					{getUrlSharing(classRoom, 'class', 'code')}
				</Alert>
				<div className="text-right">
					<ButtonCopy url={getUrlSharing(classRoom, 'class', 'code')} />
				</div>
			</div>
			<Divider className="my-3">
				<Typography variant="M14">{t('Hoặc')}</Typography>
			</Divider>
			<div className="flex flex-col gap-2">
				<Typography variant="M14">{t('Chia sẻ Mã Lớp Học này cho thành viên của bạn')}</Typography>
				<div>
					<Alert
						// icon={<Link fontSize="inherit" />}
						severity="primary"
						// variant="filled"
						className="justify-center font-500"
					>
						{classRoom?.code}
					</Alert>
					<Typography variant="M14" color="textSecondary">
						{t('và làm theo hướng dẫn dưới đây')}
					</Typography>
				</div>
			</div>
			<div className="mt-4">
				<Box
					className="flex flex-col gap-3 p-3"
					sx={{ background: theme => theme.palette.background.secondary }}
				>
					<Typography variant="M14">
						{t('1. Truy cập')}: <span className="font-bold">{`${window.location.origin}/join/class`}</span>
					</Typography>
					<Typography variant="M14">
						{t('2. Làm theo hướng dẫn trên màn hình để đăng nhập hoặc tạo tài khoản')}
					</Typography>
					<Typography variant="M14">{t('3. Nhập mã lớp học')}</Typography>
				</Box>
			</div>

			{/* <form className="flex flex-col gap-formItem" onSubmit={onSubmit}>
				<div>
					<CoreInputMultipleEmail
						control={control}
						name="student_emails"
						label={t('Mời thành viên qua email')}
						size="small"
						placeholder={t('Nhập email')}
					/>
					<Typography variant="M14">{t('Những tài khoản cos em')}</Typography>
				</div>
				<div className="text-right">
					<LoadingButton
						variant="contained"
						gradient="primary"
						type="submit"
						loading={isSubmitting}
						disabled={!isDirty}
					>
						{t('Xác nhận')}
					</LoadingButton>
				</div>
			</form> */}
		</div>
	)
}

// StudentForm.defaultProps = {}

// StudentForm.propTypes = {}

export default React.memo(StudentForm)
