import CardSkeleton from '@Core/Components/Card/CardSkeleton'
import { Button, Collapse, IconButton, Typography } from '@mui/material'
import { useBoolean } from 'ahooks'
import clsx from 'clsx'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { memo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import ExamGridItemExercise from '@Core/Components/Grid/ExamGridItemExercise'

const ExamHomeworkGroup = ({ isAdmin = false, status, data = [], loading, renderItemAction = () => {} }) => {
	const { t } = useTranslation()
	const [open, { toggle }] = useBoolean(true)
	const exercises = data?.filter(i => i?.status === status)

	const getColor = useCallback(() => {
		const colorMap = {
			upcoming: 'primary',
			going_on: 'success',
			pause: 'warning',
			finished: 'error'
		}
		return colorMap[status]
	}, [status])

	const renderExercises = () => {
		if (loading) {
			const el = Array(10)
				.fill()
				.map((item, index) => {
					return <CardSkeleton key={index} />
				})
			return <div className="flex gap-4 py-4 overflow-x-auto">{el}</div>
		}

		if (exercises.length === 0) return null

		const el = exercises.map(item => {
			return <ExamGridItemExercise isAdmin key={item.id} item={item} renderItemAction={renderItemAction} />
		})

		return <div className="flex gap-4 py-4 overflow-x-auto">{el}</div>
	}

	return (
		<div className={clsx('flex flex-col  p-5 bg-slate-100 rounded-xl')}>
			<div className="flex items-center gap-5">
				<IconButton onClick={toggle} disabled={exercises.length === 0}>
					<ExpandMoreIcon
						className={clsx('transition-all', {
							'rotate-180': open
						})}
					/>
				</IconButton>

				<Button variant="contained" disableElevation color={getColor()}>
					{t(`${status}`)}
				</Button>
				<Typography variant="M14" className="">
					{exercises.length} {t('Bài kiểm tra')}
				</Typography>
			</div>
			<Collapse in={open}>{renderExercises()}</Collapse>
		</div>
	)
}

export default memo(ExamHomeworkGroup)
