import { Box } from '@mui/material'
import React from 'react'
// import PropTypes from 'prop-types'

const PageTitle = ({ title }) => {
	return (
		<Box
			component="h2"
			variant="h2"
			sx={{
				fontSize: 20,
				color: 'text.primary',
				fontWeight: 600,
				mb: 2
			}}
		>
			{title}
		</Box>
	)
}

// PageTitle.defaultProps = {}

// PageTitle.propTypes = {}

export default React.memo(PageTitle)
