import { CoreInputSearch } from '@Core/Components/Input'
import TotalData from '@Core/Components/PageLayout/components/TotalData'
import { useTableFilter } from '@Core/Components/Table/hooks/useTableFilter'
import { useCorePageContext } from '@Core/Provider/CorePageProvider'
import { Box } from '@mui/material'
import React from 'react'
// import PropTypes from 'prop-types'

const ExamFilter = props => {
	const { tableHandler, totalData, loading, t } = useCorePageContext()
	const { methodFilter } = useTableFilter({
		handleFetchData: tableHandler.handleFetchData,
		querySearch: { search: '' }
	})
	const { control, watch } = methodFilter

	return (
		<Box className="flex space-x-6">
			<CoreInputSearch control={control} name="search" className="w-128" />
			{/* <TotalData loading={loading} totalData={totalData} title={t('Đề thi')} /> */}
		</Box>
	)
}

// ExamFilter.defaultProps = {}

// ExamFilter.propTypes = {}

export default React.memo(ExamFilter)
