import { AdminPanelSettings, Business, Public } from '@mui/icons-material'
import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
// import PropTypes from 'prop-types'

export const useStatusShareOption = ({ iconColor = 'inherit' }) => {
	const { t } = useTranslation()
	const statusOptions = useMemo(
		() => [
			{
				value: 'PRIVATE',
				label: t('Riêng tư'),
				color: 'error',
				icon: <AdminPanelSettings color={iconColor} />,
				desc: t('helper_text.private')
			},
			// {
			// 	value: 'WORKSPACE',
			// 	label: t('Tổ chức'),
			// 	color: 'error',
			// 	icon: <Business color={iconColor} />,
			// 	desc: t('helper_text.workspace')
			// },
			{
				value: 'PUBLISH',
				label: t('Công khai'),
				color: 'success',
				icon: <Public color={iconColor} />,
				desc: t('helper_text.publish')
			}
			// {
			// 	value: 'UNPUBLISH',
			// 	label: t(''),
			// 	color: 'warning'
			// }
		],
		[t]
	)

	const getOptionByValue = useCallback(
		value => {
			return statusOptions.find(i => i.value === value)
		},
		[statusOptions]
	)

	return { statusOptions, getOptionByValue }
}

// useStatusShareOption.defaultProps = {}

// useStatusShareOption.propTypes = {}
