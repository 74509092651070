import { CircularProgress, Table, TableContainer } from '@mui/material'
import { createColumnHelper, getCoreRowModel, useReactTable } from '@tanstack/react-table'
import React, { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import CoreTableBody from './components/CoreTableBody'
import CoreTableHead from './components/CoreTableHead'
import CoreTableToolbar from './components/CoreTableToolbar'
import TablePagination from './components/TablePagination'
import { useTableSelection } from './hooks/useTableSelection'
// import PropTypes from 'prop-types'

const CoreTableContext = React.createContext()

export const useCoreTableContext = () => useContext(CoreTableContext)

export const columnHelper = createColumnHelper()

const CoreTable = ({
	data = [],
	columns = [],
	total = 0,
	isShowPagination = false,
	pageSize = 10,
	pageIndex = 1,
	handleFetchData = () => {},
	loading = false,
	hasRowSelection = false,
	onConfirmMassDelete = () => {},
	hasMassDelete = false,
	...restProps
}) => {
	const { t } = useTranslation('common')
	const rerender = React.useReducer(() => ({}), {})[1]
	const defaultData = React.useMemo(() => [], [])
	const { columnSelection, rowSelection, setRowSelection } = useTableSelection({ hasRowSelection })
	const pagination = React.useMemo(
		() => ({
			pageIndex,
			pageSize
		}),
		[pageIndex, pageSize]
	)

	const columnTable = useMemo(() => {
		return [columnSelection, ...columns].filter(Boolean)
	}, [columns, rowSelection, data])

	const table = useReactTable({
		data: data ?? defaultData,
		columns: columnTable,
		pageCount: total,
		getCoreRowModel: getCoreRowModel(),
		state: {
			pagination,
			rowSelection
		},
		onRowSelectionChange: setRowSelection,
		// onPaginationChange: setPagination,
		manualPagination: true,
		debugTable: true
	})

	return (
		<CoreTableContext.Provider value={{ table, t, rowSelection, onConfirmMassDelete, hasMassDelete }}>
			<CoreTableToolbar handleFetchData={handleFetchData} t={t} />
			<TableContainer
				className="relative"
				sx={{
					'& tr > th': {
						whiteSpace: 'nowrap'
					},
					maxHeight: 'calc(100vh - 300px)'
				}}
			>
				<Table sx={{ minWidth: 650 }} stickyHeader className="table">
					<CoreTableHead table={table} columns={columns} rowSelection={rowSelection} />
					<CoreTableBody table={table} />
				</Table>
				{loading && (
					<div className="absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center z-99 table-loading">
						<CircularProgress />
					</div>
				)}
			</TableContainer>
			{isShowPagination && (
				<TablePagination
					t={t}
					pageIndex={pageIndex}
					pageSize={pageSize}
					total={total}
					fetchData={handleFetchData}
					{...restProps}
				/>
			)}
		</CoreTableContext.Provider>
	)
}

// CoreTable.defaultProps = {}

// CoreTable.propTypes = {}

export default React.memo(CoreTable)
