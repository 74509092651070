import { QUIZ_ROUTER_EXAM } from '@App/Quiz/Modules/Exam/configs/const'
import ButtonShowResult from '@Core/Components/Common/ButtonShowResult'
import ExamIconViewed from '@Core/Components/Common/Exam/ExamIconViewed'
import ExamTotalQuestion from '@Core/Components/Common/Exam/ExamTotalQuestion'
import { getExamThumnail } from '@Core/Helpers/Exam'
import { formatDate } from '@Core/Helpers/String'
import { MenuBook, PlayCircle } from '@mui/icons-material'
import { Button, Divider, Tooltip, Typography } from '@mui/material'
import { isAfter, isBefore } from 'date-fns'
import Image from 'mui-image'
import { memo, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import urlcat from 'urlcat'

const ExamGridItemExercise = ({ item, isAdmin = false, renderItemAction = () => {} }) => {
	const { t } = useTranslation()
	const { id } = useParams()
	const isCheckDateStart = isBefore(new Date(), new Date(item?.start_date))
	const isCheckDateEnd = isAfter(new Date(), new Date(item?.end_date))
	const img = useMemo(() => {
		return getExamThumnail(item)
	}, [])

	return (
		<div className="shrink-0" style={{ flexBasis: '28%' }}>
			<div className=" bg-white shadow-md p-2 md:p-4 rounded-2xl flex flex-col gap-4 transition-all duration-300 hover:shadow-lg hover:-translate-y-[0.2rem] cursor-pointer">
				<div className="w-full aspect-[22/9] relative">
					<Image src={img} title="some title" alt="alt" />
				</div>

				<Tooltip title={item?.name} placement="top-start">
					<Typography variant="M14" className="lineClamp" component="h4">
						{item?.exam?.name}
					</Typography>
				</Tooltip>
				<div>
					<div className="flex items-center gap-3">
						<Typography variant="M14">
							{t('Bắt đầu')}: {formatDate(item.start_date, 'HH:mm DD-MM-YYYY')}
						</Typography>
					</div>
					<div className="flex items-center gap-3">
						<Typography variant="M14">
							{t('Kết thúc')}: {formatDate(item.end_date, 'HH:mm DD-MM-YYYY')}
						</Typography>
					</div>
				</div>
				<div>
					<Typography variant="M14">
						{t('Thời gian')}: {item?.exam_config?.exam_test_time ?? 30}
						{t(' phút')}
					</Typography>
				</div>
				<div className="flex items-center gap-3">
					<ExamTotalQuestion exam={item?.exam} />
					{/* <ExamIconViewed exam={item?.exam} /> */}
				</div>
				<Divider className="mt-auto" />
				{renderItemAction(item)}

				{!isAdmin && (
					<div>
						{item?.is_finished ? (
							<ButtonShowResult data={item?.result} />
						) : (
							<Button
								size="small"
								gradient="primary"
								variant="contained"
								startIcon={<PlayCircle />}
								disableElevation
								disabled={
									isCheckDateStart ||
									isCheckDateEnd ||
									item?.status === 'finished' ||
									item?.is_finished
								}
								onClick={() =>
									window.open(
										urlcat(`${window.location.origin}/join/?exercise=${item.alias}`),
										'_blank'
									)
								}
							>
								{t(
									// item?.is_finished
									// 	? 'Đã hoàn thành'
									// 	: item?.status === 'finished'
									// 	? 'Đã kết thúc'
									// 	:
									isCheckDateStart ? 'Chưa diễn ra' : isCheckDateEnd ? 'Đã hết hạn' : 'Vào kiểm tra'
								)}
							</Button>
						)}
					</div>
				)}
			</div>
		</div>
	)
}

export default memo(ExamGridItemExercise)
