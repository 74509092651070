import { Navigate, createBrowserRouter } from 'react-router-dom'
import Studio from './Studio'
import { studioRouterConfig } from './Studio/appConfig'
import React from 'react'

import { ROUTER_WORKSPACE_EXAM } from './Studio/Modules/Workspace/Exam/configs/const'
import Quiz from './Quiz'
import { quizRouterConfig } from './Quiz/appConfig'
import { LazyJoinPage } from './Join/configs/router'
import Crm from './Crm'
import { crmRouterConfig } from './Crm/appConfig'
import Auth, { authRouterConfig } from './Auth'
import { ROUTER_AUTH } from './Auth/configs/const'
import Business from './Business'
import { businessRouterConfig } from './Business/appConfig'
// console.log('============= appRouterConfig', formatAppRouter(appRouterConfig))
const Page404Lazy = React.lazy(() => import('@App/CommonPage/ErrorPage/pages/Page404'))
const Page403Lazy = React.lazy(() => import('@App/CommonPage/ErrorPage/pages/Page403'))
const Page500Lazy = React.lazy(() => import('@App/CommonPage/ErrorPage/pages/Page500'))

export const appRouter = createBrowserRouter([
	{
		path: '/',
		element: <Studio />,
		children: [
			{
				path: '',
				element: <Navigate to={ROUTER_WORKSPACE_EXAM.list} />
			},
			{
				path: 'workspace',
				element: <Navigate to={ROUTER_WORKSPACE_EXAM.list} />
			},
			...studioRouterConfig
		]
	},
	{
		path: '/quiz',
		element: <Quiz />,
		children: [
			...quizRouterConfig,
			{
				path: '',
				element: <Page404Lazy />
			},
			{
				path: '*',
				element: <Page404Lazy />
			}
		]
	},
	{
		path: '/crm',
		element: <Crm />,
		children: [
			...crmRouterConfig,
			{
				path: '*',
				element: <Page404Lazy />
			}
		]
	},
	{
		path: '/auth',
		element: <Auth />,
		children: [
			...authRouterConfig,
			{
				path: '',
				element: <Page404Lazy />
			},
			{
				path: '*',
				element: <Page404Lazy />
			}
		]
	},
	{
		path: '/business',
		element: <Business />,
		children: [
			...businessRouterConfig,
			{
				path: '',
				element: <Page404Lazy />
			},
			{
				path: '*',
				element: <Page404Lazy />
			}
		]
	},
	{
		path: '/join/:type?',
		element: <LazyJoinPage />
	},
	{
		path: '*',
		element: <Page404Lazy />
	}
])
