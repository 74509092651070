import CoreDialog from '@Core/Components/Dialog/CoreDialog'
import { useBoolean } from 'ahooks'
import React from 'react'
import { useTranslation } from 'react-i18next'
import StudentForm from '../components/StudentForm'
// import PropTypes from 'prop-types'

export const useAddStudent = props => {
	const { t } = useTranslation()
	const [open, { setFalse, setTrue }] = useBoolean()

	const renderDialog = ({ classRoomDetail }) => {
		return (
			<CoreDialog
				open={open}
				handleClose={setFalse}
				dialogTitle={t('Thêm thành viên')}
				dialogContent={<StudentForm handleClose={setFalse} classRoom={classRoomDetail} />}
			/>
		)
	}

	return { renderDialog, handleOpenAddStuddent: setTrue }
}

// useAddStudent.defaultProps = {}

// useAddStudent.propTypes = {}
