import { userRole } from '@App/Studio/app-config/const'
import React from 'react'
import { ROUTER_WORKSPACE_EXAM_ROOM } from './const'

const LazyDashboard = React.lazy(() => import('../pages/Dashboard'))
const LazyExamStageDetail = React.lazy(() => import('../pages/ExamStages/ExamStageDetail'))
const LazyExamRoomList = React.lazy(() => import('../pages/ExamRoom/ExamRoomList'))
const LazyExamRoomDetail = React.lazy(() => import('../pages/ExamRoom/ExamRoomDetail'))

export const workspaceExamRoomRouterConfig = {
	auth: [userRole.admin],
	settings: {
		layout: {
			config: {
				sidebar: {
					display: true,
					collapsed: true
				},
				header: {
					display: true
				}
			}
		}
	},
	routes: [
		{
			path: ROUTER_WORKSPACE_EXAM_ROOM.stages,
			exact: true,
			element: <LazyDashboard />
		},
		{
			path: ROUTER_WORKSPACE_EXAM_ROOM.stage_detail(),
			exact: true,
			element: <LazyExamStageDetail />
		},
		// {
		// 	path: ROUTER_WORKSPACE_EXAM_ROOM.room_list,
		// 	exact: true,
		// 	element: <LazyExamRoomList isAllStage />
		// },
		{
			path: ROUTER_WORKSPACE_EXAM_ROOM.room_detail(),
			exact: true,
			element: <LazyExamRoomDetail />
		}
	]
}
