import BaseService from '@Core/axios/BaseService'

class ExamRoom extends BaseService {
	findExamRoom = alias => {
		//
		const endpoint = `/examroom/api/v1/public/exam-rooms/${alias}`
		return this.request.get(endpoint)
	}

	checkPassword = data => {
		const endpoint = 'examroom/studio/api/v1/user/exam-rooms/check-password'

		return this.request.post(endpoint, data)
	}
}

const quizExamRoomService = new ExamRoom()

export default quizExamRoomService
