import { Box, Card, CardActions, CardContent, CardHeader, Skeleton } from '@mui/material'
import React from 'react'
// import PropTypes from 'prop-types'

const CardSkeleton = props => {
	return (
		<Box className="shrink-0" sx={{ flexBasis: '30%', maxWidth: 300 }}>
			<Card>
				<Skeleton sx={{ height: 140 }} animation="wave" variant="rectangular" />

				<CardContent>
					<>
						<Skeleton animation="wave" height={15} style={{ marginBottom: 6 }} />
						<Skeleton animation="wave" height={15} width="80%" />
						<Skeleton animation="wave" height={15} width="80%" />
					</>
				</CardContent>
			</Card>
		</Box>
	)
}

// CardSkeleton.defaultProps = {}

// CardSkeleton.propTypes = {}

export default React.memo(CardSkeleton)
