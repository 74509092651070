import { Button } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useExamResultDialogContext } from './ExamResultDialogProvider'
import { BookOnline, Directions, MenuBook } from '@mui/icons-material'
import { BsBookmarkCheck } from 'react-icons/bs'
// import PropTypes from 'prop-types'

const ButtonShowResult = ({ data }) => {
	const { t } = useTranslation()
	const { handleOpenExamResult } = useExamResultDialogContext()
	return (
		<Button
			variant="contained"
			color="success"
			disableElevation
			onClick={() => handleOpenExamResult(data)}
			size="small"
			startIcon={<MenuBook />}
		>
			{t('Xem kết quả')}
		</Button>
	)
}

// ButtonShowResult.defaultProps = {}

// ButtonShowResult.propTypes = {}

export default React.memo(ButtonShowResult)
