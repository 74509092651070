import planService from '@App/Studio/services/planService'
import { useCoreAuth } from '@Core/Provider/CoreAuthProvider'
import { useRequest } from 'ahooks'
import React, { useContext } from 'react'
import { useQuizExamRoomContext } from './Modules/Exam/pages/ExamRoom/QuizExamRoomProvider'
// import PropTypes from 'prop-types'

const QuizExamPlanContext = React.createContext({
	currentSubscription: {}
})

export const useQuizExamPlanContext = () => React.useContext(QuizExamPlanContext)

const QuizExamPlanProvider = props => {
	const { isAuthenticated } = useCoreAuth()
	const { exam_room } = useQuizExamRoomContext()
	const {
		data: currentSubscription,
		runAsync: getCurrentPlanSubscription
		// loading = true
	} = useRequest(planService.getCurrentPlanSubscription, {
		ready: isAuthenticated && !exam_room?.id
	})

	const context = {
		currentSubscription
	}

	return <QuizExamPlanContext.Provider value={context}>{props.children}</QuizExamPlanContext.Provider>
}

// QuizExamPlanProvider.defaultProps = {}

// QuizExamPlanProvider.propTypes = {}

export default React.memo(QuizExamPlanProvider)
