import { Box, Typography, useTheme } from '@mui/material'
import clsx from 'clsx'
import React, { useMemo } from 'react'
import DarkModeButton from './DarkModeButton'
import ButtonFullScreen from '@Core/Components/FullScreen/ButtonFullScreen'
import AppLogo from '@Core/Components/AppLogo'
import { EDUQUIZ_APP } from 'env'
import { ROUTER_PERSONAL_DASHBOARD } from '@App/Studio/Modules/Personal/Dashboard/configs/const'
import UserHeader from './UserHeader'
import { useBreakpoints } from '@Core/hooks'
import { useQuizExamRoomContext } from '@App/Quiz/Modules/Exam/pages/ExamRoom/QuizExamRoomProvider'
import LogoBusiness from './LogoBusiness'
import { useLocation } from 'react-router-dom'
import LogoutButton from './LogoutButton'
// import PropTypes from 'prop-types'

const AppHeader = props => {
	const theme = useTheme()
	const { pathname } = useLocation()
	const { laptop } = useBreakpoints()
	const { exam_room, getExamRoomConfig, roomView } = useQuizExamRoomContext()

	const isBusiness = useMemo(() => {
		return pathname.indexOf('/quiz/room') > -1
	}, [pathname])

	return (
		<Box
			className={clsx(
				'w-screen  p-2 md:px-6  sticky top-0 shadow-md z-[99] h-[60px] md:h-[80px] flex item-center justify-between',
				{
					// hidden: !layoutConfig?.header?.display
					// (mobile && !routeSettings?.layout?.config?.mobileHeader?.display)
				}
			)}
			sx={{
				backgroundColor: theme.palette.background.main
			}}
		>
			{isBusiness ? (
				<LogoBusiness />
			) : (
				<AppLogo
					onClick={() => {
						if (!exam_room?.id) {
							const url = `${window.location.origin}${ROUTER_PERSONAL_DASHBOARD.dashboard}`
							window.open(url, '_blank')
						}
					}}
				/>
			)}

			{/* {!mobile && <CurrentWorkspace />} */}
			{laptop && !isBusiness && (
				<>
					<div className="flex items-center justify-center ml-auto">
						{getExamRoomConfig('is_fullscreen') ? '' : <ButtonFullScreen />}

						{/* <DarkModeButton /> */}
					</div>
					<UserHeader />
				</>
			)}
			{isBusiness && roomView === 'waiting' && (
				<div className="flex items-center justify-center">
					<LogoutButton />
				</div>
			)}
		</Box>
	)
}

// AppHeader.defaultProps = {}

// AppHeader.propTypes = {}

export default React.memo(AppHeader)
