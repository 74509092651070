import { Gauge, gaugeClasses } from '@mui/x-charts'
import React, { useMemo } from 'react'
// import PropTypes from 'prop-types'

const AvgTotalScore = ({ total_score }) => {
	const color = useMemo(() => {
		let colorText = '#F44336'
		if (total_score > 5 && total_score < 8) {
			colorText = '#3E65FE'
		} else if (total_score > 8) {
			colorText = '#1ebc44'
		}
		return colorText
	}, [])

	return (
		<Gauge
			width={80}
			height={80}
			value={total_score ?? 0}
			valueMax={10}
			// innerRadius={90}
			sx={theme => ({
				[`& .${gaugeClasses.valueText}`]: {
					fontSize: 13
				},
				[`& .${gaugeClasses.valueArc}`]: {
					fill: color
				},
				[`& .${gaugeClasses.referenceArc}`]: {
					fill: theme.palette.text.disabled
				}
			})}
		/>
	)
}

// AvgTotalScore.defaultProps = {}

// AvgTotalScore.propTypes = {}

export default React.memo(AvgTotalScore)
