import BaseService from '@Core/axios/BaseService'
import { getAuthTokenSession } from '@Core/Helpers/Session'
import fileDownload from 'js-file-download'

class ExamRoom extends BaseService {
	BASE_ENDPOINT = '/examroom/studio/api/v1/admin/exam-rooms'

	saveSetting = data => {
		const endpoint = '/examroom/studio/api/v1/admin/exam-rooms/save-setting'
		return this.request.post(endpoint, data)
	}

	// tạo mới Bài kiểm tra
	createExamHomework = data => {
		const endpoint = '/examroom/studio/api/v1/admin/exam-rooms/create-exercise'

		return this.request.post(endpoint, data)
	}

	// check data bài tập
	getExamExercise = examId => {
		const endpoint = `examroom/studio/api/v1/admin/exam-rooms/check-exam-exercise/${examId}`

		return this.request.get(endpoint)
	}

	statisByStatus = () => {
		const endpoint = 'examroom/studio/api/v1/admin/exam-rooms/statistical-by-status'
		return this.request.get(endpoint)
	}

	changeStatus = data => {
		const endpoint = '/examroom/studio/api/v1/admin/exam-rooms/change-status'

		return this.request.post(endpoint, data)
	}

	export = id => {
		const { access_token } = getAuthTokenSession()
		const endpoint = `/examroom/api/v1/public/exam-rooms/${id}/export-result?accessToken=${access_token}`

		// return this.request.get(endpoint)
		const url = this.BASE_URL + endpoint
		// console.log('============= url', url)
		window.open(url, '_blank')
	}
}

const adminExamRoomService = new ExamRoom()

export default adminExamRoomService
