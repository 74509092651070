import { CoreDatepicker, CoreInputSearch } from '@Core/Components/Input'
import { useTableFilter } from '@Core/Components/Table/hooks/useTableFilter'
import { useCorePageContext } from '@Core/Provider/CorePageProvider'
import { Quiz } from '@mui/icons-material'
import { Avatar, Box, Tooltip, Typography } from '@mui/material'
import { upperFirst } from 'lodash'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'

const ExamResultFilterTab = props => {
	const { tableHandler, t, user, examByResult } = useCorePageContext()
	const { methodFilter } = useTableFilter({
		handleFetchData: tableHandler.handleFetchData,
		querySearch: { search: '', created_at: '' }
	})
	const { control, watch } = methodFilter

	return (
		<Box className="flex items-center w-full space-x-6">
			{user?.id && (
				<div className="mr-auto">
					<Tooltip title={t('Thành viên')}>
						<div className="flex items-center gap-2">
							<Avatar src={user?.info?.avatar_url} className="border border-gray-200" />
							{user?.info?.fullname}
						</div>
					</Tooltip>
				</div>
			)}

			{examByResult?.id && (
				<div className="mr-auto">
					<Tooltip title={t('Đề thi ôn tập')}>
						<div className="flex items-center gap-2">
							<Quiz color="success" sx={{ width: 40, height: 40 }} />
							{upperFirst(examByResult?.name)}
						</div>
					</Tooltip>
				</div>
			)}

			{!user?.id && !examByResult?.id && <CoreInputSearch control={control} name="search" className="w-128" />}
			<div className="flex items-center">
				<Typography variant="M14" className="mr-2">
					{t('Thời gian nộp bài')}:{' '}
				</Typography>
				<CoreDatepicker control={control} name="created_at" size="small" />
			</div>
		</Box>
	)
}

export default memo(ExamResultFilterTab)
