import { errorMsg } from '@Core/Helpers/Message'
import Yup from '@Core/Helpers/Yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { useCoreAuth, useCoreAuthActions } from '@Core/Provider/CoreAuthProvider'
import { useEffect } from 'react'
import { forEach } from 'lodash'
import onboardingSerivce from '@App/Auth/services/onboardingService'
import { useCoreOnboardingContext } from '..'
import { useCoreWorkspaceContext } from '@Core/Provider/CoreWorkspaceProvider'
import { clearCache } from 'ahooks'

export const useFormAboutUs = props => {
	const { user } = useCoreAuth()
	// const { currentWorkspace } = useCoreWorkspaceContext()
	const { handleCloseOnboarding, setOnboardingCompleted } = useCoreOnboardingContext()
	// const { handleChangeStep } = props
	const { getUserInfo } = useCoreAuthActions()

	const { t } = useTranslation()
	const methodForm = useForm({
		mode: 'onTouched',
		defaultValues: {
			hear_about_us: {},
			use_app_for: {}
		}
		// resolver: yupResolver(
		// 	Yup.object({
		// 		hear_about_us: Yup.mixed().required(),
		// 		use_app_for: Yup.mixed().required()
		// 	})
		// )
	})

	useEffect(() => {
		const hear_about_us = {}
		const use_app_for = {}
		forEach(user?.info?.custom_data?.hear_about_us, item => {
			hear_about_us[item] = true
		})
		forEach(user?.info?.custom_data?.use_app_for, item => {
			use_app_for[item] = true
		})
		methodForm.setValue('hear_about_us', hear_about_us, { shouldDirty: true })
		methodForm.setValue('use_app_for', use_app_for, { shouldDirty: true })
	}, [user])

	const onSubmit = methodForm.handleSubmit(async data => {
		const convertToArr = obj => {
			return Object.keys(obj).reduce((result, key) => {
				if (obj[key]) {
					return [...result, key]
				}
				return result
			}, [])
		}
		try {
			const hear_about_us = convertToArr(data.hear_about_us)
			const use_app_for = convertToArr(data.use_app_for)
			if (hear_about_us.length === 0) {
				methodForm.setError('hear_about_us', { message: t('yup.mixed.required') })
				return
			}
			if (use_app_for.length === 0) {
				methodForm.setError('use_app_for', { message: t('yup.mixed.required') })
				return
			}
			const payload = {
				hear_about_us,
				use_app_for
			}
			await onboardingSerivce.updateStepHearAbout(payload)
			clearCache('getUserInfo')
			getUserInfo()
			setOnboardingCompleted(true)
			// handleChangeStep(3)
			// handleCloseOnboarding()
			// if (currentWorkspace?.id) {
			// 	handleCloseOnboarding()
			// } else {
			// 	setStep('create-workpsace')
			// }
			handleCloseOnboarding()
		} catch (e) {
			errorMsg(e)
		}
	})

	return { methodFormStepThree: methodForm, onSubmitStepThree: onSubmit }
}
