import { Box, Skeleton } from '@mui/material'
import React from 'react'
// import PropTypes from 'prop-types'

const GridItemSkeleton = props => {
	return (
		<div className="shrink-0" style={{ flexBasis: '28%' }}>
			<div className="bg-white shadow-md p-2 md:p-4 rounded-2xl flex flex-col gap-4 transition-all duration-300 hover:shadow-lg hover:-translate-y-[0.2rem] cursor-pointer">
				<Skeleton variant="rectangular" height={140} />
				<Box sx={{ pt: 0.5 }}>
					<div className="flex justify-between">
						<Skeleton width="20%" />
						<Skeleton variant="circular" width={40} height={40} />
					</div>
					<Skeleton />
				</Box>
			</div>
		</div>
	)
}

// GridItemSkeleton.defaultProps = {}

// GridItemSkeleton.propTypes = {}

export default React.memo(GridItemSkeleton)
